import { useState } from 'react'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Autocomplete, CircularProgress, Container, FormControl, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import FORMIK from 'modules/formik'
import { useCreateListBannerMutation, useGetEventDropdownMutation } from 'store/serviceBookingEventBanner'
import type { PartialBannerCreateDataProps, PartialBannerCreateProps } from 'modules/partial'
import type {
  DetailStateProps,
  ErrorProps
} from 'modules/types'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'


const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [getEventDropdown, eventDropdown] = useGetEventDropdownMutation()
  const [createBannerList, create] = useCreateListBannerMutation()

  const scheme = yup.object<PartialBannerCreateProps>({
    Image: yup.string().required('BannerImage is required'),
    data: yup.object<PartialBannerCreateDataProps>({
      EventId: yup.string().required('EventId is required'),
      ValidFrom: yup.string().required('ValidFrom is required'),
      ValidUntil: yup.string().required('ValidUntil is required'),
      Status: yup.string().required('Status Type is required')
    })
  })

  const initialValues: PartialBannerCreateProps = {
    Image: {} as File,
    data: {
      bannerImage: '',
      EventId: '',
      ValidFrom: '',
      ValidUntil: '',
      Status: ''
    }
  }

  const formik = FORMIK.useFormFormik<PartialBannerCreateProps>(
    scheme,
    initialValues,
    (values) => {
      const formData = new FormData()

      const data = {
        ...values.data
      }

      if (values.Image) formData.append('Image', values.Image)
      formData.append('data', JSON.stringify(data))
      createBannerList(formData)
    }
  )
  const requestToken = formik.values.data

  // console.log('validFrom', payloadDate.validFrom);
  // console.log('formik', formik);
  // const disabled =
  //     !formik.isValid || isEmpty(previewImage)

  const [selectedValue, setSelectedValue] = useState("option1");
  const handleRadioChange = (value: string) => {
    console.log('valueChecked', value);
    setSelectedValue(value);
  };

  return (
    <>
      <Dialog
        title='Create New Discount'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      // isDisabled={disabled}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            {...DialogStyle.textField}
            id='code'
            variant='outlined'
            label='Discount Name'
            onChange={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
            onBlur={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
            value={''}
            // error={formik.touched && formik.touched.code && Boolean(formik.errors.code)}
            // helperText={
            //   formik.touched && formik.touched.code && formik.errors && formik.errors.code
            // }
            fullWidth
          />
          <TextField
            id='Description'
            variant='outlined'
            label='Description'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            // error={
            //   formik.touched && formik.touched.Description && Boolean(formik.errors.Description)
            // }
            // helperText={
            //   formik.touched &&
            //   formik.touched.Description &&
            //   formik.errors &&
            //   formik.errors.Description
            // }
            fullWidth
          />
          <Typography>Discount Type</Typography>
          <form style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ width: "260px" }}>
              <input type="radio" id="option1" value="option1" checked={selectedValue === "option1"}
                onChange={() => handleRadioChange("option1")} style={{height: 16, width: 16}}/>
              <label htmlFor="option1" style={{ marginLeft: "8px", fontSize: "17px", color: "#333" }}>Percentage</label>
            </div>
            <div style={{ width: "260px" }}>
              <input type="radio" id="option2" value="option2" checked={selectedValue === "option2"}
                onChange={() => handleRadioChange("option2")} style={{height: 16, width: 16}}/>
              <label htmlFor="option2" style={{ marginLeft: "8px", fontSize: "17px", color: "#333" }}>Deduct Price</label>
            </div>
          </form>
          <FormControl fullWidth>
            <InputLabel htmlFor='electricityFee'>Discount Amount</InputLabel>
            {selectedValue === 'option1' ? (
              <OutlinedInput
                id='discountAmount'
                label='Discount Amount'
                type='number'
                // value={formik.values.electricityFee && formik.values.electricityFee.toString()}
                value={''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={
                //   formik.touched &&
                //   formik.touched.electricityFee &&
                //   Boolean(formik.errors.electricityFee)
                // }
                inputProps={{ autoComplete: 'off' }}
                onKeyDown={(event) => {
                  if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                }}
                endAdornment={<InputAdornment position='end'>%</InputAdornment>}
              />
            ) : (
              <OutlinedInput
                id='discountAmount'
                label='Discount Amount'
                type='number'
                // value={formik.values.electricityFee && formik.values.electricityFee.toString()}
                value={''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                // error={
                //   formik.touched &&
                //   formik.touched.electricityFee &&
                //   Boolean(formik.errors.electricityFee)
                // }
                inputProps={{ autoComplete: 'off' }}
                onKeyDown={(event) => {
                  if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                }}
                startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
              />
            )}
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidFrom) : undefined}
              onChange={(e: Dayjs | null) => {
                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                formik.setFieldValue('data.ValidFrom', newDate)
                // setPayloadDate({ ...payloadDate, validFrom: newDate })
                // setDate((prevState) => ({ ...prevState, validFrom: e }))
              }}
              label='Start Date'
              format='DD-MM-YYYY'
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={requestToken && requestToken.ValidUntil ? dayjs(requestToken.ValidUntil) : undefined}
              minDate={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidFrom) : undefined}
              onChange={(e: Dayjs | null) => {

                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                formik.setFieldValue('data.ValidUntil', newDate)
                // setPayloadDate({ ...payloadDate, validUntil: newDate })
                // setDate((prevState) => ({ ...prevState, validUntil: e }))
              }}
              label='End Date'
              format='DD-MM-YYYY'
            />
          </LocalizationProvider>
          <Autocomplete
            options={(eventDropdown && eventDropdown.data) || []}
            getOptionLabel={(list) => list.text}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.value == value.value : false
            }
            onOpen={() => getEventDropdown()}
            onChange={(_, id) => formik.setFieldValue('data.EventId', id && id.value)}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.value}>
                {item.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='EventId'
                label='Applicable Subscription Plan'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {eventDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          {/* <FormControl fullWidth>
            <InputLabel id='Status'>Status</InputLabel>
            <Select
              labelId='Status'
              id='Status'
              variant='outlined'
              label='Voucher Type *'
              value={formik.values.data.Status}
              onChange={(event) => {
                formik.setFieldValue('data.Status', '')
                formik.setFieldValue('data.Status', event.target.value)
              }}
              error={
                touched && Boolean(touched.Status) && error && Boolean(error.Status)
              }
            >
              <MenuItem value='Enabled'>Enabled</MenuItem>
              <MenuItem value='Disabled'>Disabled</MenuItem>
            </Select>
          </FormControl> */}
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
