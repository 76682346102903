// import { useState } from 'react'
// import type { SetStateAction } from 'react'
// import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Autocomplete, Button, CircularProgress, Container, Grid, Typography } from '@mui/material'
import TextField from '@mui/material/TextField';
import ReactQuill from 'react-quill'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import FORMIK from 'modules/formik'
import { useCreateListBannerMutation, useGetEventDropdownMutation } from 'store/serviceBookingEventBanner'
import type { PartialBannerCreateDataProps, PartialBannerCreateProps } from 'modules/partial'
import type {
  // CallbackHandler,
  DetailStateProps,
  ErrorProps
} from 'modules/types'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'



const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [getEventDropdown, eventDropdown] = useGetEventDropdownMutation()
  const [createBannerList, create] = useCreateListBannerMutation()


  const scheme = yup.object<PartialBannerCreateProps>({
    Image: yup.string().required('BannerImage is required'),
    data: yup.object<PartialBannerCreateDataProps>({
      EventId: yup.string().required('EventId is required'),
      ValidFrom: yup.string().required('ValidFrom is required'),
      ValidUntil: yup.string().required('ValidUntil is required'),
      Status: yup.string().required('Status Type is required')
    })
  })

  const initialValues: PartialBannerCreateProps = {
    Image: {} as File,
    data: {
      bannerImage: '',
      EventId: '',
      ValidFrom: '',
      ValidUntil: '',
      Status: ''
    }
  }

  const formik = FORMIK.useFormFormik<PartialBannerCreateProps>(
    scheme,
    initialValues,
    (values) => {
      const formData = new FormData()

      const data = {
        ...values.data
      }

      if (values.Image) formData.append('Image', values.Image)
      formData.append('data', JSON.stringify(data))
      createBannerList(formData)
    }
  )

  // const error = formik.errors && (formik.errors.data as unknown as PartialBannerCreateDataProps)
  // const touched = formik.touched && (formik.touched as PartialBannerCreateDataProps)
  // const requestToken = formik.values.data

  const onPress = () => {
    alert('diklik loo');
  }

  return (
    <>
      <Dialog
        title='New Copywriting'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      // isDisabled={disabled}
      >
        <Container {...DialogStyle.Container}>
          <Autocomplete
            options={(eventDropdown && eventDropdown.data) || []}
            getOptionLabel={(list) => list.text}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.value == value.value : false
            }
            onOpen={() => getEventDropdown()}
            onChange={(_, id) => formik.setFieldValue('data.EventId', id && id.value)}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.value}>
                {item.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='EventId'
                label='Category'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {eventDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <TextField
            {...DialogStyle.textField}
            id='code'
            variant='outlined'
            label='Copywriting Name'
            onChange={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
            onBlur={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
            value={''}
            // error={formik.touched && formik.touched.code && Boolean(formik.errors.code)}
            // helperText={
            //   formik.touched && formik.touched.code && formik.errors && formik.errors.code
            // }
            fullWidth
          />
          <Grid item xs={12}>
            <Typography {...DialogStyle.Title}>Bahasa ID</Typography>
            <ReactQuill
              theme='snow'
              placeholder='Description'
              // value={formik.values.data.informationId}
              value={''}
              onChange={(e) => formik.setFieldValue('data.informationId', GLOBAL.emptyQuill(e))}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography {...DialogStyle.Title}>Language EN-US</Typography>
            <ReactQuill
              theme='snow'
              placeholder='Description'
              // value={formik.values.data.informationId}
              value={''}
              onChange={(e) => formik.setFieldValue('data.informationId', GLOBAL.emptyQuill(e))}
            />
          </Grid>
        </Container>
        <Button variant='contained' onClick={onPress}>
          Preview
        </Button>
      </Dialog>



      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
