import React, { useState } from 'react'
import { useFormik } from 'formik'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import type { PartialPromoCodeProps, PartialPromoCodeTempProps } from 'modules/partial'
import type {
  DetailStateProps,
  DropdownCategoryListProps,
  ErrorProps,
  MemberTypeDropDown,
  TableVoucherPromoCodeProps
} from 'modules/types'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import { useDropDownPromoCodeBatchRewardMutation } from 'store/promoCodeBatch'
import { useGetMemberTypeDropdownMutation } from 'store/dropdown'
import { useGetVoucherListDropdownMutation } from 'store/tokenRequest'
import { useCreatePromoCodeMutation } from 'store/promoCode'
import Dialog from 'components/dialog/dialog'
import DialogStyle from './style'

const Create = ({ open, onClose }: DetailStateProps) => {
  const [dropDownPromoCodeBatchReward, dropdownReward] = useDropDownPromoCodeBatchRewardMutation()
  const [getVoucherListDropdown, voucherDropdown] = useGetVoucherListDropdownMutation()
  const [getMemberTypeDropdown, memberType] = useGetMemberTypeDropdownMutation()
  const [createPromoCode, create] = useCreatePromoCodeMutation()
  const [tableVoucher, setTableVoucher] = useState<TableVoucherPromoCodeProps[]>([])
  const [listTempVoucher, setListTempVoucher] = useState<DropdownCategoryListProps>({
    id: '',
    name: '',
    key: '',
    language: ''
  })
  const [valVoucher, setValVoucher] = useState<string>('')
  const [randomKey, setRandomKey] = useState<number>(0)
  const [qty, setQty] = useState<number | string>('')
  const [rewardType, setRewardType] = useState<string>('')
  const [userType, setUserType] = useState<MemberTypeDropDown[]>([])
  const [payloadDate, setPayloadDate] = useState<{
    minSignUpDate: string
    maxSignUpDate: string
  }>({
    minSignUpDate: '',
    maxSignUpDate: ''
  })
  const [payload, setPayload] = useState<{
    coinsAmount: number | null
    maxRedemption: number | null
  }>({
    coinsAmount: null,
    maxRedemption: null
  })
  const [date, setDate] = useState<{
    minDateSignUp: Dayjs | null
    maxDateSignUp: Dayjs | null
    validFrom: Dayjs | null
    validUntil: Dayjs | null
  }>({
    minDateSignUp: null,
    maxDateSignUp: null,
    validFrom: null,
    validUntil: null
  })

  const scheme = yup.object<PartialPromoCodeTempProps>({
    code: yup.string().required('Promo Code Name is required'),
    name: yup.string().required('Campaign Name is required'),
    rewardType: yup.string().required('Reward is required'),
    validFrom: yup.string().required('Valid From is required'),
    validTo: yup.string().required('Valid Until is required'),
    messageTitle: yup.string().required('Message Title is required'),
    messageBody: yup.string().required('Message Title is required')
  })

  const formik = useFormik<PartialPromoCodeTempProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      code: '',
      name: '',
      rewardType: '',
      validFrom: '',
      validTo: '',
      messageTitle: '',
      messageBody: ''
    },
    onSubmit: (values: PartialPromoCodeTempProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialPromoCodeTempProps) => {
    const formatUser =
      userType?.length > 0
        ? userType.map((item) => ({
          memberId: item.id,
          memberName: item.name
        }))
        : []

    const formatVoucher =
      tableVoucher?.length > 0
        ? tableVoucher.map((item) => ({
          quantity: item.qty,
          voucherId: item.id,
          voucherName: item.name
        }))
        : []

    const formatPayload: PartialPromoCodeProps = {
      ...e,
      minSignUpDate: !isEmpty(payloadDate.minSignUpDate) ? payloadDate.minSignUpDate : null,
      maxSignUpDate: !isEmpty(payloadDate.maxSignUpDate) ? payloadDate.maxSignUpDate : null,
      ...payload,
      validFrom: dayjs(e.validFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      validTo: dayjs(e.validTo).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      members: formatUser,
      vouchers: formatVoucher
    }

    createPromoCode(formatPayload)
  }

  const onDelTableChargerPoint = (id: string | number) => {
    const tempTable = tableVoucher.filter((item) => item.id !== id)
    setTableVoucher(tempTable)
  }

  const onAddChargerPoint = () => {
    const combineVal = { ...listTempVoucher, qty }
    const tempTable = tableVoucher.concat(combineVal)
    setTableVoucher(tempTable)
    setValVoucher('')
    setQty('')
    setRandomKey(randomKey + 1)
    setListTempVoucher({
      id: '',
      name: '',
      key: '',
      language: ''
    })
  }

  const DescMessage = () => (
    <Box sx={{ marginBottom: '10px' }}>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        *You can use these placeholder in the field above
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{userFullName} `}</span>
        Name of the recipient
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{voucherName} `}</span>
        Voucher Title
      </Typography>
      <Typography variant='caption' display='block' gutterBottom color='#525252'>
        <span
          style={{ fontSize: '14px', fontWeight: '500', color: '#242423' }}
        >{`{coinsAmount} `}</span>
        Coin amount given to user
      </Typography>
    </Box>
  )

  const [selectedValue, setSelectedValue] = useState("option1");
  const handleRadioChange = (value: string) => {
    console.log('valueChecked', value);
    setSelectedValue(value);
  };

  return (
    <>
      <Dialog
        isDisabled={!formik.isValid}
        title='New Promo Code General'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        maxWidth='md'
      >
        <Container {...DialogStyle.Container}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
              <TextField
                {...DialogStyle.textField}
                id='name'
                focused
                variant='outlined'
                label='Promo Name EN-US'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={formik.touched && formik.touched.name && Boolean(formik.errors.name)}
                helperText={
                  formik.touched && formik.touched.name && formik.errors && formik.errors.name
                }
                fullWidth
              />
              <TextField
                {...DialogStyle.textField}
                id='code'
                variant='outlined'
                label='Promo Code'
                onChange={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
                onBlur={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
                value={formik.values.code}
                error={formik.touched && formik.touched.code && Boolean(formik.errors.code)}
                helperText={
                  formik.touched && formik.touched.code && formik.errors && formik.errors.code
                }
                fullWidth
              />
              <TextField
                {...DialogStyle.textField}
                id='code'
                variant='outlined'
                label='Quantity'
                onChange={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
                onBlur={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
                value={formik.values.code}
                error={formik.touched && formik.touched.code && Boolean(formik.errors.code)}
                helperText={
                  formik.touched && formik.touched.code && formik.errors && formik.errors.code
                }
                fullWidth
              />
              <FormControl style={{marginTop: 40, marginBottom: 12}}>
                <Typography style={{ marginBottom: 12}}>Promo Type</Typography>
                <form style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div>
                    <input type="radio" id="option1" value="option1" checked={selectedValue === "option1"}
                      onChange={() => handleRadioChange("option1")} style={{ height: 16, width: 16 }} />
                    <label htmlFor="option1" style={{ marginLeft: "8px", fontSize: "17px", color: "#333" }}>Percentage</label>
                  </div>
                  <div>
                    <input type="radio" id="option2" value="option2" checked={selectedValue === "option2"}
                      onChange={() => handleRadioChange("option2")} style={{ height: 16, width: 16 }} />
                    <label htmlFor="option2" style={{ marginLeft: "8px", fontSize: "17px", color: "#333" }}>Deduct Price</label>
                  </div>
                </form>
              </FormControl>
              <TextField
                {...DialogStyle.textField}
                id='code'
                variant='outlined'
                label='Maximum Deduction'
                onChange={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
                onBlur={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
                value={formik.values.code}
                error={formik.touched && formik.touched.code && Boolean(formik.errors.code)}
                helperText={
                  formik.touched && formik.touched.code && formik.errors && formik.errors.code
                }
                fullWidth
              />
                   <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={date.validFrom}
                  sx={{ width: '100%', marginBottom: '10px' }}
                  onChange={(e) => {
                    const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                    formik.setFieldValue('validFrom', newDate)
                    setDate((prevState) => ({ ...prevState, validFrom: e }))
                  }}
                  label='Valid From'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
              <Autocomplete
                sx={{ marginBottom: '10px' }}
                options={(dropdownReward && dropdownReward.data) || []}
                getOptionLabel={(option) => option.text}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.value == value.value : false
                }
                onOpen={() => dropDownPromoCodeBatchReward()}
                onChange={(_, reward) => {
                  if (!isEmpty(reward)) {
                    formik.setFieldValue('rewardType', reward.text)
                    setRewardType(reward.text)
                  }
                }}
                value={
                  (dropdownReward &&
                    dropdownReward.data &&
                    dropdownReward.data.find((e) => e.text == rewardType)) ||
                  undefined
                }
                ListboxProps={GlobalStyle.ListBox}
                renderOption={(props, item) => (
                  <li {...props} key={item.value}>
                    {item.text}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name='Reward'
                    label='Reward'
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {dropdownReward.isLoading && (
                            <CircularProgress color='inherit' size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                  />
                )}
              />
              {rewardType === 'Voucher' && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Autocomplete
                      key={'Voucher-' + randomKey}
                      sx={{ width: '70%', marginBottom: '10px', marginRight: '5px' }}
                      options={voucherDropdown.data || []}
                      getOptionLabel={(list) => list.name}
                      isOptionEqualToValue={(option, value) =>
                        option && value ? option.id == value.id : false
                      }
                      onOpen={() => getVoucherListDropdown()}
                      onChange={(_, voucher) => {
                        if (voucher) {
                          setListTempVoucher(voucher)
                          setValVoucher(voucher.name)
                        }
                      }}
                      ListboxProps={GlobalStyle.ListBox}
                      value={
                        voucherDropdown &&
                        voucherDropdown.data &&
                        voucherDropdown.data.find((e) => e.name == valVoucher)
                      }
                      renderOption={(props, item) => (
                        <li {...props} key={item.id}>
                          {item.name}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name='voucherId'
                          label='Voucher'
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {voucherDropdown.isLoading && (
                                  <CircularProgress color='inherit' size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            )
                          }}
                        />
                      )}
                    />
                    <TextField
                      sx={{ width: '30%' }}
                      id='longitude'
                      variant='outlined'
                      label='Qty'
                      type='number'
                      inputProps={{
                        min: '0'
                      }}
                      value={qty}
                      onChange={(e) => setQty(parseInt(e.target.value))}
                    />
                    <Button
                      sx={{ height: '56px', marginLeft: '5px' }}
                      variant='contained'
                      onClick={onAddChargerPoint}
                      disabled={isEmpty(listTempVoucher && qty?.toString())}
                    >
                      Add
                    </Button>
                  </Box>
                  <TableContainer
                    component={Paper}
                    sx={{ maxHeight: 512, marginBottom: '10px' }}
                    variant='outlined'
                  >
                    <Table aria-label='Data Table' stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Voucher</TableCell>
                          <TableCell>Qty</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableVoucher.map((table, index) => (
                          <TableRow key={index}>
                            <TableCell>{table.name}</TableCell>
                            <TableCell>{table.qty}</TableCell>

                            <TableCell>
                              <IconButton
                                onClick={() => onDelTableChargerPoint(table.id)}
                                sx={{ color: 'red' }}
                              >
                                <Icon icon='Delete' />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TextField
                    id='Maximal'
                    variant='outlined'
                    {...DialogStyle.textField}
                    inputProps={{
                      min: '0'
                    }}
                    label='Maximal Redemption Count(max token distributed for this campaign)'
                    fullWidth
                    value={payload.maxRedemption}
                    onChange={(e) =>
                      setPayload({ ...payload, maxRedemption: parseInt(e.target.value) })
                    }
                    type='number'
                  />
                </>
              )}

              {rewardType === 'Coin' && (
                <TextField
                  id='Coins'
                  variant='outlined'
                  {...DialogStyle.textField}
                  label='Coin Amount'
                  fullWidth
                  value={payload.coinsAmount}
                  onChange={(e) =>
                    setPayload({ ...payload, coinsAmount: parseInt(e.target.value) })
                  }
                  inputProps={{
                    min: '0'
                  }}
                  type='number'
                />
              )}
            </Box>
            <Box sx={{ display: 'flex', flex: 1, gap: 2, flexDirection: 'column' }}>
              <TextField
                {...DialogStyle.textField}
                id='name'
                focused
                variant='outlined'
                label='Promo Name ID'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={formik.touched && formik.touched.name && Boolean(formik.errors.name)}
                helperText={
                  formik.touched && formik.touched.name && formik.errors && formik.errors.name
                }
                fullWidth
              />
              <TextField
                {...DialogStyle.textField}
                id='name'
                focused
                variant='outlined'
                label='Promo Name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                error={formik.touched && formik.touched.name && Boolean(formik.errors.name)}
                helperText={
                  formik.touched && formik.touched.name && formik.errors && formik.errors.name
                }
                fullWidth
              />
              <TextField
                id="description"
                name='description'
                variant='outlined'
                label="Description"
                // onChange={(e) => setPayload({ ...payload, description: e.target.value })}
                multiline
                rows={4}
                value={''}
              />
                <FormControl>
                <InputLabel htmlFor='electricityFee'>Promo Amount</InputLabel>
                {selectedValue === 'option1' ? (
                  <OutlinedInput
                    id='discountAmount'
                    label='Discount Amount'
                    type='number'
                    // value={formik.values.electricityFee && formik.values.electricityFee.toString()}
                    value={''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // error={
                    //   formik.touched &&
                    //   formik.touched.electricityFee &&
                    //   Boolean(formik.errors.electricityFee)
                    // }
                    inputProps={{ autoComplete: 'off' }}
                    onKeyDown={(event) => {
                      if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                    }}
                    endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                  />
                ) : (
                  <OutlinedInput
                    id='discountAmount'
                    label='Discount Amount'
                    type='number'
                    // value={formik.values.electricityFee && formik.values.electricityFee.toString()}
                    value={''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // error={
                    //   formik.touched &&
                    //   formik.touched.electricityFee &&
                    //   Boolean(formik.errors.electricityFee)
                    // }
                    inputProps={{ autoComplete: 'off' }}
                    onKeyDown={(event) => {
                      if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
                    }}
                    startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
                  />
                )}
              </FormControl>
              <TextField
                {...DialogStyle.textField}
                id='code'
                variant='outlined'
                label='Minimum Payment'
                onChange={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
                onBlur={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
                value={formik.values.code}
                error={formik.touched && formik.touched.code && Boolean(formik.errors.code)}
                helperText={
                  formik.touched && formik.touched.code && formik.errors && formik.errors.code
                }
                fullWidth
              />
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={date.validUntil}
                  minDate={dayjs(formik.values.validFrom)}
                  sx={{ width: '100%', marginBottom: '10px' }}
                  onChange={(e) => {
                    const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                    formik.setFieldValue('validTo', newDate)
                    setDate((prevState) => ({ ...prevState, validUntil: e }))
                  }}
                  label='Valid Until'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
              <Autocomplete
                fullWidth
                sx={{ marginBottom: '10px' }}
                multiple
                id='fixed-tags-demo'
                value={userType}
                onOpen={() => getMemberTypeDropdown()}
                isOptionEqualToValue={(option, value) =>
                  option && value ? option.name == value.name : false
                }
                onChange={(event, newValue) => {
                  setUserType(newValue)
                }}
                options={(memberType && memberType.data) || []}
                getOptionLabel={(option) => option.name}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip label={option.name} {...getTagProps({ index })} key={index} />
                  ))
                }
                renderInput={(params) => <TextField {...params} label='User Type' />}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={date.minDateSignUp}
                  sx={{ width: '100%', marginBottom: '10px' }}
                  onChange={(e) => {
                    const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                    setPayloadDate({ ...payloadDate, minSignUpDate: newDate })
                    setDate((prevState) => ({ ...prevState, minDateSignUp: e }))
                  }}
                  label='Minimum Sign Up Date'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                <DatePicker
                  value={date.maxDateSignUp}
                  minDate={dayjs(payloadDate.minSignUpDate)}
                  sx={{ width: '100%', marginBottom: '10px' }}
                  onChange={(e) => {
                    const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                    setPayloadDate({ ...payloadDate, maxSignUpDate: newDate })
                    setDate((prevState) => ({ ...prevState, maxDateSignUp: e }))
                  }}
                  label='Maximum Sign Up Date'
                  format='DD-MM-YYYY'
                />
              </LocalizationProvider>
        
              <TextField
                id='messageTitle'
                variant='outlined'
                label='Message Title'
                {...DialogStyle.textField}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.messageTitle}
                error={
                  formik.touched &&
                  formik.touched.messageTitle &&
                  Boolean(formik.errors.messageTitle)
                }
                helperText={
                  formik.touched &&
                  formik.touched.messageTitle &&
                  formik.errors &&
                  formik.errors.messageTitle
                }
                fullWidth
              />
              <DescMessage />
              <TextField
                id='messageBody'
                variant='outlined'
                label='Message Body'
                {...DialogStyle.textField}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.messageBody}
                error={
                  formik.touched && formik.touched.messageBody && Boolean(formik.errors.messageBody)
                }
                helperText={
                  formik.touched &&
                  formik.touched.messageBody &&
                  formik.errors &&
                  formik.errors.messageBody
                }
                fullWidth
              />
              <DescMessage />
            </Box>
          </Box>
        </Container>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
