import { useState } from 'react';
import type { ChangeEvent } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Autocomplete, Chip, Container, Grid, IconButton, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AddOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import Dialog from 'components/dialog/dialog';
import Notification from 'components/notification';
import GLOBAL from 'modules/global';
// import FORMIK from 'modules/formik';
import { useCreateListBannerMutation } from 'store/serviceBookingEventBanner';
import type { PartialSubsContentCreateProps } from 'modules/partial';
import { useGetMemberTypeDropdownMutation } from 'store/dropdown';
import type { DetailStateProps, ErrorProps, MemberTypeDropDown } from 'modules/types';
import DialogStyle from './style';


const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>;

  const [createSubsContent, create] = useCreateListBannerMutation();
  const [getMemberTypeDropdown, memberType] = useGetMemberTypeDropdownMutation();
  const [userType, setUserType] = useState<MemberTypeDropDown[]>([])

  // type InputField = {
  //   ContentEnglish: string;
  // };

  // const [inputFields, setInputFields] = useState<InputField[]>([{ ContentEnglish: '' }]);
  const [inputFieldsEnglish, setInputFieldsEnglish] = useState([{ ContentEnglish: '' }]);
  const [inputFieldsBahasa, setInputFieldsBahasa] = useState([{ ContentBahasa: '' }]);

  const scheme = yup.object<PartialSubsContentCreateProps>({
    Code: yup.string().required('code is required'),
    Subtitle: yup.string().required('subtitle is required'),
    ValidFrom: yup.string().required('ValidFrom is required'),
    ValidUntil: yup.string().required('ValidUntil is required'),
    Status: yup.string().required('Status Type is required'),
    ContentEnglish: yup.string().required('Content English is required'),
    ContentBahasa: yup.string().required('Content Bahasa is required')
  });

  // const initialValues: PartialSubsContentCreateProps = {
  //     bannerImage: '',
  //     Code: '',
  //     Subtitle: '',
  //     ValidFrom: '',
  //     ValidUntil: '',
  //     Status: '',
  //     ContentEnglish: '',
  //     ContentBahasa: ''

  // };

  // const formik = FORMIK.useFormFormik<PartialSubsContentCreateProps>(
  //   schema,
  //   initialValues,
  //   (values) => {
  //     const formData = new FormData();
  //     const data = {
  //       ...values,
  //       ContentEnglish: inputFieldsEnglish.map(field => field.ContentEnglish).join(', '),
  //       ContentBahasa: inputFieldsBahasa.map(field => field.ContentBahasa).join(', '),
  //     };

  //     onSubmit: (values: PartialSubsContentCreateProps) => handleSubmit(values)
  //   }
  // );
  const formik = useFormik<PartialSubsContentCreateProps>({
    validationSchema: scheme,
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      bannerImage: '',
      Code: '',
      Subtitle: '',
      ValidFrom: '',
      ValidUntil: '',
      Status: '',
      ContentEnglish: [],
      ContentBahasa: []
    },
    onSubmit: (values: PartialSubsContentCreateProps) => handleSubmit(values)
  })

  const handleSubmit = (e: PartialSubsContentCreateProps) => {
    const formatPayload: PartialSubsContentCreateProps = {
      ...e,
      ContentEnglish: inputFieldsEnglish.map(field => field.ContentEnglish),
      ContentBahasa: inputFieldsBahasa.map(field => field.ContentBahasa),
    }
    // createSubsContent(formatPayload)
    console.log('payloaddd', formatPayload);
    createSubsContent
  }

  const errorData = formik.errors

  const handleChangeInputEnglish = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = e.target;
    const list = [...inputFieldsEnglish];
    list[index].ContentEnglish = value;
    setInputFieldsEnglish(list);
  };
  const handleChangeInputBahasa = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { value } = e.target;
    const list = [...inputFieldsBahasa];
    list[index].ContentBahasa = value;
    setInputFieldsBahasa(list);
  };

  const handleAddFieldsEnglish = () => {
    if (inputFieldsEnglish.length < 10) { // Batasi penambahan field hingga 10
      setInputFieldsEnglish([...inputFieldsEnglish, { ContentEnglish: '' }]); // Add a new input field
    }
  };
  const handleAddFieldsBahasa = () => {
    if (inputFieldsBahasa.length < 10) { // Batasi penambahan field hingga 10
      setInputFieldsBahasa([...inputFieldsBahasa, { ContentBahasa: '' }]); // Add a new input field
    }
  };

  const handleRemoveFieldsEnglish = (index: number) => {
    const values = [...inputFieldsEnglish];
    values.splice(index, 1); // Remove the specific input field
    setInputFieldsEnglish(values);
  };
  const handleRemoveFieldsBahasa = (index: number) => {
    const values = [...inputFieldsBahasa];
    values.splice(index, 1); // Remove the specific input field
    setInputFieldsBahasa(values);
  };

  console.log('isi inputFieldsEnglish =>', inputFieldsEnglish);
  // console.log('isi inputFieldsBAHASA =>', inputFieldsBahasa);
  // console.log('isi formik =>', formik.values.Code);
  console.log('values => ', formik.initialValues);

  return (
    <>
      <Dialog
        title='Create New Subscription Content'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            {...DialogStyle.textField}
            id='Code'
            variant='outlined'
            label='Subscription Content Name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Code} // Menggunakan value dari formik
            // value={''}
            error={formik.touched.Code && Boolean(errorData.Code)}
            helperText={formik.touched.Code && errorData.Code}

            fullWidth
          />
          <TextField
            {...DialogStyle.textField}
            id='Subtitle'
            variant='outlined'
            label='Subtitle'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.Subtitle} // Menggunakan value dari formik
            error={formik.touched.Subtitle && Boolean(errorData.Subtitle)}
            helperText={formik.touched.Subtitle && errorData.Subtitle}
            fullWidth
          />
          <Autocomplete
            fullWidth
            sx={{ marginBottom: '10px' }}
            multiple
            id='fixed-tags-demo'
            value={userType}
            onOpen={() => getMemberTypeDropdown()}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.name === value.name : false
            }
            onChange={(event, newValue) => {
              setUserType(newValue)
            }}
            options={(memberType && memberType.data) || []}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip label={option.name} {...getTagProps({ index })} key={index} />
              ))
            }
            renderInput={(params) => <TextField {...params} label='Applicable Subscription Plan' />}
          />
          <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: -10 }}>
            <Typography {...DialogStyle.Title} style={{ width: '90%' }}>Content English</Typography>
            <IconButton onClick={handleAddFieldsEnglish} style={{ width: '10%' }}>
              <AddOutlined style={{ width: 24, height: 24, color: '#002C5F', marginRight: 2 }} /> <Typography {...DialogStyle.TitleAdd} style={{ color: '#002C5F' }}>Add</Typography>
            </IconButton>
          </Grid>
          {inputFieldsEnglish.slice(0, 10).map((input, index) => (
            <Grid key={index} item xs={12} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <TextField
                variant='outlined'
                label={`Content English #${index + 1}`}
                name={`ContentEnglish-${index}`}
                value={input.ContentEnglish}
                // value={formik.values.data.ContentEnglish}
                onChange={(e) => {
                  handleChangeInputEnglish(e, index),
                    console.log('input', input);
                }}
                style={{ width: '95%' }}
              />
              <Grid style={{ display: 'flex', flexDirection: 'column' }}>

                {inputFieldsEnglish.length > 1 && ( // Kondisi untuk menampilkan RemoveOutlined
                  <IconButton onClick={() => handleRemoveFieldsEnglish(index)}>
                    <DeleteOutlineOutlined style={{ width: 24, height: 24, color: '#002C5F' }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
          <Grid style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: -10 }}>
            <Typography {...DialogStyle.Title} style={{ width: '90%' }}>Content Bahasa</Typography>
            <IconButton onClick={handleAddFieldsBahasa} style={{ width: '10%' }}>
              <AddOutlined style={{ width: 24, height: 24, color: '#002C5F', marginRight: 2 }} /> <Typography {...DialogStyle.TitleAdd} style={{ color: '#002C5F' }}>Add</Typography>
            </IconButton>
          </Grid>
          {inputFieldsBahasa.slice(0, 10).map((input, index) => (
            <Grid key={index} item xs={12} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
              <TextField
                variant='outlined'
                label={`Content Bahasa #${index + 1}`}
                name={`ContentBahasa-${index}`}
                value={input.ContentBahasa}
                // value={formik.values.data.ContentEnglish}
                onChange={(e) => {
                  handleChangeInputBahasa(e, index),
                    console.log('input', input);
                }}
                style={{ width: '95%' }}
              />
              <Grid style={{ display: 'flex', flexDirection: 'column' }}>

                {inputFieldsBahasa.length > 1 && ( // Kondisi untuk menampilkan RemoveOutlined
                  <IconButton onClick={() => handleRemoveFieldsBahasa(index)}>
                    <DeleteOutlineOutlined style={{ width: 24, height: 24, color: '#002C5F' }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          ))}
        </Container>
      </Dialog>
      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  );
};

export default Create;