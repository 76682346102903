import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import Icon from 'components/icon'
import Loading from 'components/loading/loading'
import MenuModalItem from 'components/menuItem/menuItem'
import Notification from 'components/notification'
import CONSTANT from 'modules/constant'
import GLOBAL from 'modules/global'
import type { PartialPromoCodeBatchProps } from 'modules/partial'
import GlobalStyle from 'modules/styles'
import type { ErrorProps } from 'modules/types'
import {
  useDeletePromoCodeBatchMutation,
  useDownloadPromoCodeBatchMutation,
  useDropDownPromoCodeBatchRewardMutation,
  useDropDownPromoCodeBatchStatusMutation,
  useGetPromoCodeBatchListMutation
} from 'store/promoCodeBatch'
import Create from './create/create'
import Detail from './detail/detail'
import Edit from './edit/edit'

const PromoBatchCategory = () => {
  const navigate = useNavigate()
  const [getPromoCodeBatchList, promoCodeBatch] = useGetPromoCodeBatchListMutation()
  const [downloadPromoCodeBatch, download] = useDownloadPromoCodeBatchMutation()
  const [deletePromoCodeBatch, deletePromo] = useDeletePromoCodeBatchMutation()
  const [dropDownPromoCodeBatchReward, dropdownReward] = useDropDownPromoCodeBatchRewardMutation()
  const [dropDownPromoCodeBatchStatus, dropdownStatus] = useDropDownPromoCodeBatchStatusMutation()

  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [randomNumber, setRandomNumber] = useState({
    numberStatus: 0,
    rewardNumber: 0
  })
  const [payload, setPayload] = useState<PartialPromoCodeBatchProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    RewardType: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidTo: '',
    MaxValidTo: '',
    MinCreatedAt: '',
    MaxCreatedAt: '',
    Status: ''
  })

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [filter, setFilter] = useState<{
    RewardType: string
    Status: string
    MinCreatedAt: string
    MaxCreatedAt: string
  }>({
    RewardType: '',
    MinCreatedAt: '',
    MaxCreatedAt: '',
    Status: ''
  })
  const [date, setDate] = useState<{
    minDateSignUp: Dayjs | null
    maxDateSignUp: Dayjs | null
  }>({
    minDateSignUp: null,
    maxDateSignUp: null
  })

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidTo)) formData.append('MinValidTo', payload.MinValidTo)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    getPromoCodeBatchList(formData)
  }

  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', String(0))
    formData.append('Length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidTo)) formData.append('MinValidTo', payload.MinValidTo)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadPromoCodeBatch(formData)
  }
  const onCreate = () => setDialogCreate(true)

  useEffect(() => {
    onResync()
  }, [payload])

  const tableHeadTitles = [
    'Prefix',
    'Campaign Name',
    'Promo Code Quantity',
    'Code Length',
    'Reward Type',
    'Progress',
    'Notes',
    'Task Status',
    'Valid From',
    'Valid Until'
  ]
  const numbers = GLOBAL.tableNumber(payload.Start)

  const totalPage = promoCodeBatch.data && GLOBAL.tableTotalPage(promoCodeBatch.data.recordsTotal)

  const openAnchor = Boolean(anchor)

  return (
    <>
      <Content
        title='Promo Code Batch List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        onAdditional={onCreate}
        additionalTitle='Create New Promo Batch'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={promoCodeBatch.data && promoCodeBatch.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={promoCodeBatch.isLoading}
        useDownloadDropdown
      >
        {promoCodeBatch.isSuccess &&
          promoCodeBatch.data &&
          promoCodeBatch.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      onRemoveAnchor(() =>
                        navigate('list', {
                          state: { id: table.id.toString(), name: table.prefix }
                        })
                      )
                    }
                  >
                    Open
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem>

                  <MenuModalItem
                    title={table.prefix}
                    onClick={() => onRemoveAnchor(() => deletePromoCodeBatch(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                {drawerDetail && menu == index && (
                  <Detail
                    id={table.id}
                    open={drawerDetail && menu == index}
                    onClose={() => setDrawerDetail(false)}
                  />
                )}
                {dialogUpdate && menu == index && (
                  <Edit
                    id={table.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )}
              </TableCell>
              <TableCell>{table.prefix || '-'}</TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.quantity || '-'}</TableCell>
              <TableCell>{table.codeLength || '-'}</TableCell>
              <TableCell>{table.rewardType || '-'}</TableCell>
              <TableCell>{table.progress || '-'}</TableCell>
              <TableCell>{table.notes || '-'}</TableCell>
              <TableCell>{table.status || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDate(table.validFrom) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDate(table.validTo) || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>
      {promoCodeBatch.isLoading && <Loading />}
      <Notification
        open={
          !promoCodeBatch.isLoading && !promoCodeBatch.isUninitialized && !promoCodeBatch.isSuccess
        }
        onClose={() => (promoCodeBatch.isError ? promoCodeBatch.reset() : location.reload())}
        isError={Boolean(promoCodeBatch.error) && promoCodeBatch.isError}
        message={GLOBAL.returnExceptionMessage(
          promoCodeBatch.isError,
          promoCodeBatch.error as ErrorProps
        )}
      />
      <Notification
        open={!deletePromo.isLoading && !deletePromo.isUninitialized}
        onClose={() => (deletePromo.isError ? deletePromo.reset() : location.reload())}
        isError={Boolean(deletePromo.error) && deletePromo.isError}
        message={GLOBAL.returnExceptionMessage(
          deletePromo.isError,
          deletePromo.error as ErrorProps
        )}
      />
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            RewardType: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidTo: '',
            MaxValidTo: '',
            MinCreatedAt: '',
            MaxCreatedAt: '',
            Status: ''
          })
          setFilter({ RewardType: '', MinCreatedAt: '', MaxCreatedAt: '', Status: '' })
          setDate({
            minDateSignUp: null,
            maxDateSignUp: null
          })
          setRandomNumber({
            numberStatus: randomNumber.numberStatus + 1,
            rewardNumber: randomNumber.rewardNumber + 1
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.minDateSignUp}
                sx={{ width: '100%', marginBottom: '10px' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter({ ...filter, MinCreatedAt: newDate })
                  setDate((prevState) => ({ ...prevState, minDateSignUp: e }))
                }}
                label='Minimum Sign Up Date'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.maxDateSignUp}
                minDate={dayjs(filter.MinCreatedAt)}
                sx={{ width: '100%', marginBottom: '10px' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter({ ...filter, MaxCreatedAt: newDate })
                  setDate((prevState) => ({ ...prevState, maxDateSignUp: e }))
                }}
                label='Maximum Sign Up Date'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              key={`Status` + randomNumber.rewardNumber}
              options={(dropdownReward && dropdownReward.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() => dropDownPromoCodeBatchReward()}
              onChange={(_, reward) => {
                if (!isEmpty(reward)) setFilter({ ...filter, RewardType: reward?.text })
              }}
              value={
                (dropdownReward &&
                  dropdownReward.data &&
                  dropdownReward.data.find((e) => e.text == filter.RewardType)) ||
                undefined
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='Reward'
                  label='Reward'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {dropdownReward.isLoading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              key={`Status` + randomNumber.numberStatus}
              options={(dropdownStatus && dropdownStatus.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() => dropDownPromoCodeBatchStatus()}
              onChange={(_, status) => {
                if (!isEmpty(status)) setFilter({ ...filter, Status: status.text })
              }}
              value={
                (dropdownStatus &&
                  dropdownStatus.data &&
                  dropdownStatus.data.find((e) => e.text == filter.RewardType)) ||
                undefined
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='Task Status'
                  label='Task Status'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {dropdownStatus.isLoading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Filter>
    </>
  )
}

export default PromoBatchCategory
