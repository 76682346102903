import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Grid,
    TextField,
    Typography
  } from '@mui/material'
  import { ExpandMoreOutlined } from '@mui/icons-material'
  import Drawer from 'components/drawer/detail'
  import Loading from 'components/loading/loading'
  import type { DetailStateProps } from 'modules/types'
  import { useGetVoucherUsageDetailQuery } from 'store/voucherUsage'
  import DrawerStyle from './style'
  
  const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
    if (!open) return <Drawer open={open} onClose={onClose} />
  
    const { data, isFetching, isLoading } = useGetVoucherUsageDetailQuery(id)
  
    const loading = !data && (isLoading || isFetching)
  
    const voucherName = (data && data.voucherName) || '-'
    const partnerName = (data && data.partnerName) || '-'
    const tokenCount = data && data.tokenCount
    const available = data && data.available
    const purchased = data && data.purchased
    const claimed = data && data.claimed
    const revoked = data && data.revoked
    const expired = data && data.expired
  
    const gridDataLabel = [
      'Voucher Name',
      'Partner Name',
      'Token Count',
      'Available',
      'Purchased',
      'Claimed',
      'Revoked',
      'Expired'
    ]
  
    const gridDataValue = [
      voucherName,
      partnerName,
      tokenCount,
      available,
      purchased,
      claimed,
      revoked,
      expired
    ]
  
    const createdBy = (data && data.createdBy) || '-'
    const createdAt = (data && data.createdAt) || '-'
    const modifiedBy = (data && data.modifiedBy) || '-'
    const modifiedAt = (data && data.modifiedAt) || '-'
  
    const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
    const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]
  
    return (
      <>
        <Drawer open={open} title='Voucher Usage Detail' onClose={onClose}>
          <Container {...DrawerStyle.Container}>
            {loading && <Loading />}
            {!loading && (
              <>
                <Grid container spacing={2}>
                  {gridDataValue.map((item, index) => (
                    <Grid key={index} item xs={12}>
                      <TextField
                        variant='outlined'
                        label={gridDataLabel[index]}
                        value={item}
                        inputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                  ))}
                </Grid>
                <Accordion elevation={0} disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls='basic-information'
                  >
                    <Typography>Basic Information</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {gridBasicValue.map((item, index) => (
                        <Grid key={index} item xs={12}>
                          <TextField
                            variant='outlined'
                            label={gridBasicLabel[index]}
                            value={item}
                            inputProps={{ readOnly: true }}
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Container>
        </Drawer>
      </>
    )
  }
  
  export default Detail
  