import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { KeyboardArrowDown, SearchRounded } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import type { MenuProps } from '@mui/material'
import {
  Box,
  Button,
  Container,
  FilledInput,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  styled,
  alpha,
  TablePagination,
  Checkbox
} from '@mui/material'
import type { Children } from 'modules/types'
import Icon from 'components/icon'
import CONSTANT from 'modules/constant'
import Dialog from 'components/dialog/dialog'
import useDebounce from 'modules/useDebounce'
import ContentStyle from './style'

type ContentProps = {
  title: string
  additionalTitle?: string
  additionalPrefixIcon?: string
  additionalSuffixIcon?: string
  additionalLoading?: boolean
  downloadTitle?: string
  downloadLoading?: boolean
  tableHeadTitles?: string[]
  totalRecords?: number
  totalPage?: number
  page?: number
  isLoading?: boolean
  useNoAction?: boolean
  useAction?: boolean
  useAdjustRenewalAction?: boolean
  useDownloadDropdown?: boolean
  useBackButton?: boolean
  usePagination?: boolean
  withBtnSelectAll?: boolean
  uploadTitle?: string
  uploadPrefixIcon?: string
  uploadSuffixIcon?: string
  uploadLoading?: boolean
  useTable?: boolean
  useTemplateDownloadDropdown?: boolean
  templateDownloadLoading?: boolean
  templateTitle?: string
  useCheckbox?: boolean
  allChecked?: Array<unknown>
  editValidityPrefixIcon?: string
  revokePrefixIcon?: string
  movePrefixIcon?: string
  actionTitle?: string
  btnEditCheckBox?: string
  onSearch?: (value: string) => void
  onFilter?: () => void
  onResync?: () => void
  onDownload?: (types?: string) => void
  onAdditional?: () => void
  onUpload?: () => void
  onChangePerPage?: (value: number) => void
  pagination?: (value: number) => void
  onTemplateDownload?: (type?: string) => void
  onCheckBoxAll?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onClickCheckBoxAll?: () => void
  onEditValidity?: () => void
  onRevokeToken?: () => void
  onMoveToken?: () => void
  onAction?: () => void
  onEditCheckBox?: () => void
}

const StyledMenu = styled((props: MenuProps) => (
  <MuiMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode == 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
      }
    }
  }
}))

const Menu = (props: ContentProps) => {
  const prefix = props.additionalPrefixIcon ? <Icon icon={props.additionalPrefixIcon} /> : null
  const suffix = props.additionalSuffixIcon ? <Icon icon={props.additionalSuffixIcon} /> : null
  const prefixUpload = props.uploadPrefixIcon ? <Icon icon={props.uploadPrefixIcon} /> : null
  const suffixUpload = props.uploadSuffixIcon ? <Icon icon={props.uploadSuffixIcon} /> : null

  const [search, setSearch] = useState<string>('')
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [focus, setFocus] = useState<boolean>(false)
  const [anchorTemplate, setAnchorTemplate] = useState<null | HTMLElement>(null)

  const [resync, setResync] = useState<boolean>(false)
  const openAnchorTemplate = Boolean(anchorTemplate)

  const openAnchor = Boolean(anchor)
  const debounce = useDebounce(search)

  useEffect(() => {
    if (props.onSearch && focus) props.onSearch(search)
  }, [debounce])

  return (
    <>
      <Box {...ContentStyle.Menu}>
        {props.onSearch && (
          <FilledInput
            id='search'
            size='small'
            placeholder='Search'
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            onFocus={() => setFocus(true)}
            startAdornment={
              <InputAdornment position='start'>
                <SearchRounded />
              </InputAdornment>
            }
            {...ContentStyle.Search}
          />
        )}
        {props.onFilter && (
          <Button variant='contained' onClick={props.onFilter}>
            <Icon icon='FilterAlt' />
          </Button>
        )}
        {props.onResync && (
          <Button variant='contained' onClick={() => setResync(true)}>
            <Icon icon='Sync' />
          </Button>
        )}
        {props.onDownload && (
          <>
            {props.useDownloadDropdown && (
              <Box>
                <LoadingButton
                  variant='contained'
                  id='button-trigger'
                  aria-haspopup='true'
                  aria-controls={openAnchor ? 'button-account' : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => setAnchor(event.currentTarget)}
                  startIcon={<Icon icon='Download' />}
                  endIcon={<KeyboardArrowDown />}
                  loading={props.downloadLoading}
                >
                  {props.downloadTitle || 'Download'}
                </LoadingButton>
                <StyledMenu
                  id='button-account'
                  aria-labelledby='button-trigger'
                  anchorEl={anchor}
                  open={openAnchor}
                  onClose={() => setAnchor(null)}
                >
                  {CONSTANT.DEFAULT_DOWNLOAD_TYPES.map((item, index) => (
                    <MuiMenuItem
                      key={index}
                      onClick={() => {
                        setAnchor(null)
                        props.onDownload && props.onDownload(item)
                      }}
                    >
                      <Icon icon='InsertDriveFile' />
                      {item}
                    </MuiMenuItem>
                  ))}
                </StyledMenu>
              </Box>
            )}
            {!props.useDownloadDropdown && (
              <LoadingButton
                variant='contained'
                {...ContentStyle.Additional}
                startIcon={<Icon icon='Download' />}
                onClick={() => props.onDownload && props.onDownload()}
                loading={props.downloadLoading}
              >
                {props.downloadTitle || 'Download'}
              </LoadingButton>
            )}
          </>
        )}
        {props.onTemplateDownload && (
          <>
            {props.useTemplateDownloadDropdown && (
              <Box>
                <LoadingButton
                  variant='contained'
                  id='button-trigger'
                  aria-haspopup='true'
                  aria-controls={openAnchorTemplate ? 'button-account' : undefined}
                  aria-expanded={openAnchorTemplate ? 'true' : undefined}
                  onClick={(event) => setAnchorTemplate(event.currentTarget)}
                  startIcon={<Icon icon='InsertDriveFile' />}
                  endIcon={<KeyboardArrowDown />}
                  loading={props.templateDownloadLoading}
                >
                  {props.templateTitle || 'Template'}
                </LoadingButton>
                <StyledMenu
                  id='button-account'
                  aria-labelledby='button-trigger'
                  anchorEl={anchorTemplate}
                  open={openAnchorTemplate}
                  onClose={() => setAnchorTemplate(null)}
                >
                  {CONSTANT.DEFAULT_DOWNLOAD_TEMPLATE.map((item, index) => (
                    <MuiMenuItem
                      key={index}
                      onClick={() => {
                        setAnchorTemplate(null)
                        props.onTemplateDownload && props.onTemplateDownload(item)
                      }}
                    >
                      <Icon icon='InsertDriveFile' />
                      {item}
                    </MuiMenuItem>
                  ))}
                </StyledMenu>
              </Box>
            )}
            {!props.useTemplateDownloadDropdown && (
              <LoadingButton
                variant='contained'
                {...ContentStyle.Additional}
                startIcon={<Icon icon='Download' />}
                onClick={() => props.onTemplateDownload && props.onTemplateDownload()}
                loading={props.templateDownloadLoading}
              >
                {props.templateTitle || 'Template'}
              </LoadingButton>
            )}
            {!props.useDownloadDropdown && (
              <LoadingButton
                variant='contained'
                {...ContentStyle.Additional}
                startIcon={<Icon icon='Download' />}
                onClick={() => props.onDownload && props.onDownload()}
                loading={props.downloadLoading}
              >
                {props.downloadTitle || 'Download'}
              </LoadingButton>
            )}
          </>
        )}
        {props.onUpload && (
          <LoadingButton
            variant='contained'
            {...ContentStyle.Additional}
            startIcon={prefixUpload}
            endIcon={suffixUpload}
            onClick={props.onUpload}
            loading={props.uploadLoading}
          >
            {props.uploadTitle || 'Upload CSV'}
          </LoadingButton>
        )}
        {props.onAdditional && (
          <LoadingButton
            variant='contained'
            {...ContentStyle.Additional}
            startIcon={prefix}
            endIcon={suffix}
            onClick={props.onAdditional}
            loading={props.additionalLoading}
          >
            {props.additionalTitle || 'Additional Button'}
          </LoadingButton>
        )}
      </Box>
      <Dialog
        title='Resync'
        submitText='Yes'
        open={resync}
        loading={false}
        onCancel={() => setResync(false)}
        onSubmit={() => {
          setResync(false)
          props && props.onResync && props.onResync()
        }}
      >
        <Typography>Are you sure want to resync this item?</Typography>
      </Dialog>
    </>
  )
}

const MenuCheckbox = (props: ContentProps) => {
  const prefixEditValidity = props.editValidityPrefixIcon ? (
    <Icon icon={props.editValidityPrefixIcon} />
  ) : null
  const prefixRevoke = props.revokePrefixIcon ? <Icon icon={props.revokePrefixIcon} /> : null
  const prefixMove = props.movePrefixIcon ? <Icon icon={props.movePrefixIcon} /> : null

  return (
    <>
      <Box {...ContentStyle.Menu}>
        {props.onEditValidity && (
          <LoadingButton
            variant='contained'
            {...ContentStyle.Additional}
            startIcon={prefixEditValidity}
            onClick={props.onEditValidity}
            loading={props.additionalLoading}
          >
            Edit Validity
          </LoadingButton>
        )}
        {props.onRevokeToken && (
          <LoadingButton
            variant='contained'
            {...ContentStyle.Additional}
            startIcon={prefixRevoke}
            onClick={props.onRevokeToken}
            loading={props.additionalLoading}
            sx={{ backgroundColor: 'red', ':hover': { backgroundColor: 'red' } }}
          >
            Revoke Token
          </LoadingButton>
        )}
        {props.onMoveToken && (
          <LoadingButton
            variant='contained'
            {...ContentStyle.Additional}
            startIcon={prefixMove}
            onClick={props.onMoveToken}
            loading={props.additionalLoading}
            sx={{ backgroundColor: 'red', ':hover': { backgroundColor: 'red' } }}
          >
            Move Token
          </LoadingButton>
        )}
        {props.onAction && (
          <LoadingButton
            variant='contained'
            {...ContentStyle.Additional}
            onClick={props.onAction}
            loading={props.additionalLoading}
            sx={{ backgroundColor: 'red', ':hover': { backgroundColor: 'red' } }}
          >
            {props.actionTitle || 'Send'}
          </LoadingButton>
        )}
      </Box>
    </>
  )
}

const Data = ({
  children,
  useTable = true,
  useNoAction = true,
  useAction = true,
  useAdjustRenewalAction = false,
  useCheckbox = false,
  onCheckBoxAll,
  allChecked = [],
  ...props
}: ContentProps & Children) => {
  if (!useTable) return <>{children}</>

  return (
    <TableContainer component={Paper} variant='outlined' {...ContentStyle.Table}>
      <Table aria-label='Data Table' stickyHeader>
        <TableHead>
          <TableRow>
            {useCheckbox && (
              <>
                <TableCell>
                  <Checkbox onChange={onCheckBoxAll} checked={allChecked.length > 0} />
                </TableCell>
              </>
            )}
            {useNoAction && (
              <>
                <TableCell>No</TableCell>
                {useAction && <TableCell>Action</TableCell>}
                {useAdjustRenewalAction && <TableCell>Adjust Renewal</TableCell>}
              </>
            )}
            {props.tableHeadTitles &&
              props.tableHeadTitles.map((title, index) => (
                <TableCell key={index} {...ContentStyle.NoWrap}>
                  {title}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  )
}

const Content = ({
  children,
  useTable = true,
  usePagination = true,
  ...props
}: ContentProps & Children) => {
  const navigate = useNavigate()

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = parseInt(event.target.value, 10)
    CONSTANT.DEFAULT_PAGINATION_PER_PAGE = value
    props.onChangePerPage && props.onChangePerPage(value)
  }

  return (
    <Container {...ContentStyle.Container}>
      <Box {...ContentStyle.Header}>
        <Box {...ContentStyle.Navigation}>
          {props.useBackButton && (
            <IconButton onClick={() => navigate(-1)}>
              <Icon icon='ArrowBack' />
            </IconButton>
          )}
          <Typography {...ContentStyle.Title}>{props.title}</Typography>
        </Box>
        <Menu {...props} />
      </Box>
      {props.allChecked && props.allChecked.length > 0 && (
        <Box {...ContentStyle.Header}>
          <Box {...ContentStyle.Navigation}>
            <Typography sx={{ color: 'red' }}>{props.allChecked.length} Row(s) Selected</Typography>
            {props.withBtnSelectAll && (
              <>
                <Button variant='contained' onClick={props.onClickCheckBoxAll}>
                  Select All
                </Button>
                <Button variant='contained' onClick={props.onEditCheckBox}>
                  {props.btnEditCheckBox}
                </Button>
              </>
            )}
          </Box>
          <MenuCheckbox {...props} />
        </Box>
      )}
      {!props.isLoading && (
        <>
          <Data {...props} useTable={useTable}>
            {children}
          </Data>
          {useTable && (
            <Container {...ContentStyle.Pagination}>
              {props.totalRecords !== undefined && props.totalRecords <= 0 && (
                <Container {...ContentStyle.NotFound}>Data Not Found</Container>
              )}
              {usePagination && (
                <Box {...ContentStyle.PaginationWrapper}>
                  <Pagination
                    count={props.totalPage || 1}
                    page={props.page}
                    onChange={(_, page) => props.pagination && props.pagination(page)}
                    showFirstButton
                    showLastButton
                  />
                  <TablePagination
                    component='div'
                    count={props.totalPage || 1}
                    page={props.page ? (props.page - 1) / CONSTANT.DEFAULT_PAGINATION_PER_PAGE : 0}
                    onPageChange={() => undefined}
                    rowsPerPage={CONSTANT.DEFAULT_PAGINATION_PER_PAGE}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={() => <></>}
                  />
                  <Typography sx={{ fontSize: '.875rem' }}>
                    Total Records:&nbsp;&nbsp;{props.totalRecords}
                  </Typography>
                </Box>
              )}
            </Container>
          )}
        </>
      )}
    </Container>
  )
}

export default Content
