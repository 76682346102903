import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  TextField,
  Autocomplete
} from '@mui/material'
import {
  useDownloadVoucherUsageListMutation,
  useGetVoucherUsageListMutation,
  useGetVoucherUsageStatusDropdownMutation
} from 'store/voucherUsage'
import Content from 'components/content/content'
import Filter from 'components/filter/filter'
import CONSTANT from 'modules/constant'
import type { PartialFormDataCapsProps, PartialVoucherUsageProps } from 'modules/partial'
import Notification from 'components/notification'
import type { ErrorProps } from 'modules/types'
import GLOBAL from 'modules/global'
import Loading from 'components/loading/loading'
import Icon from 'components/icon'
import GlobalStyle from 'modules/styles'
import MenuModalItem from 'components/menuItem/menuItem'
import { useGetListPartnerLocationDropdownMutation } from 'store/locationPartner'
import Create from './create/create'
import Detail from './detail/detail'


const SubscriptionContentList = () => {
  const [getVoucherUsageList, listVoucherUsage] = useGetVoucherUsageListMutation()
  const [downloadVoucherUsageList, download] = useDownloadVoucherUsageListMutation()

  const [getPartnerDropdownList, partnerDropdown] = useGetListPartnerLocationDropdownMutation()
  const [getVoucherUsageStatusDropdownList, statusDropdown] =
    useGetVoucherUsageStatusDropdownMutation()

  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  const [menu, setMenu] = useState<null | number>(null)
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [randomKey, setRandomKey] = useState<number>(0)

  const [filter, setFilter] = useState<PartialVoucherUsageProps>({
    CategoryId: '',
    SubCategoryId: '',
    CompanyId: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidUntil: '',
    MaxValidUntil: '',
    Status: '',
    DateTimeRange: '',
    HasExpired: ''
  })

  const [payload, setPayload] = useState<PartialVoucherUsageProps & PartialFormDataCapsProps>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: { value: '' },
    CategoryId: '',
    SubCategoryId: '',
    CompanyId: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidUntil: '',
    MaxValidUntil: '',
    Status: '',
    DateTimeRange: '',
    HasExpired: ''
  })

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: { value } })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })

  const onResync = () => {
    const formData = new FormData()

    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search.value))
      formData.append('Search', payload.Search.value)

    if (!isEmpty(payload.SubCategoryId)) formData.append('SubCategoryId', payload.SubCategoryId)
    if (!isEmpty(payload.CompanyId)) formData.append('CompanyId', payload.CompanyId)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidUntil)) formData.append('MinValidUntil', payload.MinValidUntil)
    if (!isEmpty(payload.MaxValidUntil)) formData.append('MaxValidUntil', payload.MaxValidUntil)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.DateTimeRange)) formData.append('DateTimeRange', payload.DateTimeRange)
    if (!isEmpty(payload.HasExpired)) formData.append('HasExpired', String(payload.HasExpired))

    getVoucherUsageList(formData)
  }

  const onDownload = (type?: string) => {
    const formData = new FormData()

    formData.append('start', String(0))
    formData.append('length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search) && !isEmpty(payload.Search.value))
      formData.append('Search', payload.Search.value)

    if (!isEmpty(payload.SubCategoryId)) formData.append('SubCategoryId', payload.SubCategoryId)
    if (!isEmpty(payload.CompanyId)) formData.append('CompanyId', payload.CompanyId)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidUntil)) formData.append('MinValidUntil', payload.MinValidUntil)
    if (!isEmpty(payload.MaxValidUntil)) formData.append('MaxValidUntil', payload.MaxValidUntil)
    if (!isEmpty(payload.Status)) formData.append('Status', payload.Status)
    if (!isEmpty(payload.DateTimeRange)) formData.append('DateTimeRange', payload.DateTimeRange)
    if (!isEmpty(payload.HasExpired)) formData.append('HasExpired', String(payload.HasExpired))

    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadVoucherUsageList(formData)
  }

  const onCreate = () => setDialogCreate(true)

  const onFilter = (state: boolean) => setDialogFilter(state)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback: CallableFunction) => {
    setAnchor(null)
    callback(callback)
  }

  const tableHeadTitles = [
    'Subscription Content Name',
    'Applicable Subscription Plan',
    'Subtitle',
    'Status'
  ]

  const openAnchor = Boolean(anchor)
  const totalPage =
    listVoucherUsage.data && GLOBAL.tableTotalPage(listVoucherUsage.data.recordsTotal)
  const numbers = GLOBAL.tableNumber(payload.Start)

  useEffect(() => {
    onResync()
  }, [payload])

  return (
    <>
      <Content
        title='Subscription Content List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={listVoucherUsage.data && listVoucherUsage.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={listVoucherUsage.isLoading}
        useDownloadDropdown
        onAdditional={onCreate}
        additionalTitle='New Subscription Content'
        additionalPrefixIcon='Add'
      >
        {listVoucherUsage.isSuccess &&
          listVoucherUsage.data &&
          listVoucherUsage.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View Detail
                  </MenuItem>
                  <MenuModalItem
                    // title={table.status == 'Enabled' ? 'Disable' : 'Enable'}
                    title={'Enable'}
                  // onClick={() =>
                  //   onRemoveAnchor(() =>
                  //     updateUsersAccountList({
                  //       userId: table.userId,
                  //       IsActive: table.status != 'Enabled'
                  //     })
                  //   )
                  // }
                  >
                    {/* {table.status == 'Enabled' ? 'Disable' : 'Enable'} */}
                    Enable
                  </MenuModalItem>
                </Menu>
                <Detail
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                  id={table.id}
                />
              </TableCell>
              <TableCell>{table.voucherName || '-'}</TableCell>
              <TableCell>{table.partnerName || '-'}</TableCell>
              <TableCell>{table.tokenCount}</TableCell>
              <TableCell>{table.available}</TableCell>
            </TableRow>
          ))}
      </Content>
      {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />}
      {listVoucherUsage.isLoading && <Loading />}

      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomKey(randomKey + 1)
          setFilter({
            CategoryId: '',
            SubCategoryId: '',
            CompanyId: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidUntil: '',
            MaxValidUntil: '',
            Status: '',
            DateTimeRange: '',
            HasExpired: ''
          })
          setPayload({
            ...payload,
            Start: 0,
            CategoryId: '',
            SubCategoryId: '',
            CompanyId: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidUntil: '',
            MaxValidUntil: '',
            Status: '',
            DateTimeRange: '',
            HasExpired: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({ ...payload, Start: 0, ...filter })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(partnerDropdown && partnerDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getPartnerDropdownList()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              CompanyId: (partner && String(partner.id)) || ''
            })
          }
          value={
            (partnerDropdown &&
              partnerDropdown.data &&
              partnerDropdown.data.find((e) => String(e.id) == filter.CompanyId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='partnerId'
              label='Subscription Plan'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {partnerDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(statusDropdown && statusDropdown.data) || []}
          getOptionLabel={(option) => option.text}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.value == value.value : false
          }
          onOpen={() => getVoucherUsageStatusDropdownList()}
          onChange={(_, status) =>
            setFilter({
              ...filter,
              Status: (status && String(status.value)) || ''
            })
          }
          value={
            (statusDropdown &&
              statusDropdown.data &&
              statusDropdown.data.find((e) => String(e.value) == filter.Status)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.value}>
              {item.text}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='status'
              label='Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {statusDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>

      <Notification
        open={
          !listVoucherUsage.isLoading &&
          !listVoucherUsage.isUninitialized &&
          !listVoucherUsage.isSuccess
        }
        onClose={() => (listVoucherUsage.isError ? listVoucherUsage.reset() : location.reload())}
        isError={Boolean(listVoucherUsage.error) && listVoucherUsage.isError}
        message={GLOBAL.returnExceptionMessage(
          listVoucherUsage.isError,
          listVoucherUsage.error as ErrorProps
        )}
      />

      <Notification
        open={!download.isLoading && !download.isUninitialized}
        onClose={() => download.reset()}
        isError={Boolean(download.error) && download.isError}
        message={GLOBAL.returnExceptionMessage(download.isError, download.error as ErrorProps)}
      />
    </>
  )
}
export default SubscriptionContentList
