import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Filter from 'components/filter/filter'
import Content from 'components/content/content'
import type { ErrorProps } from 'modules/types'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import {
  useDeletePromoCodeMutation,
  // useDownloadPromoCodeMutation,
  useGetListPromoCodeMutation
} from 'store/promoCode'
import type { PartialSubsPlan } from 'modules/partial'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useGetListPartnerLocationDropdownMutation } from 'store/locationPartner'
// import Create from './create/create'
// import Edit from './edit/edit'
import Detail from './detail/detail'

const NotificationBluelinkList = () => {
  const [getListPromoCode, promoCode] = useGetListPromoCodeMutation()
  const [getPartnerDropdownList, partnerDropdown] = useGetListPartnerLocationDropdownMutation()
  // const [downloadPromoCode, download] = useDownloadPromoCodeMutation()
  const [deletePromoCode, deletePromo] = useDeletePromoCodeMutation()
  // const [date, setDate] = useState<FilterDatePromoCodeProps>({
  //   minValidFrom: null,
  //   maxValidFrom: null,
  //   minValidUntil: null,
  //   maxValidUntil: null,
  //   minCreatedDate: null,
  //   maxCreateDate: null
  // })
  const [filter, setFilter] = useState({
    CompanyId: '',
    RewardType: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidTo: '',
    MaxValidTo: '',
    MinCreatedAt: '',
    MaxCreatedAt: ''
  })
  const [payload, setPayload] = useState<PartialSubsPlan>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    CompanyId: '',
    RewardType: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidTo: '',
    MaxValidTo: '',
    MinCreatedAt: '',
    MaxCreatedAt: '',
    Status: ''
  })
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
//   const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  // const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [randomNumber, setRandomNumber] = useState<number>(0)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.CompanyId)) formData.append('CompanyId', payload.CompanyId)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidTo)) formData.append('MinValidTo', payload.MinValidTo)
    if (!isEmpty(payload.MaxValidTo)) formData.append('MaxValidTo', payload.MaxValidTo)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)

    getListPromoCode(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  // const onDownload = (type?: string) => {
  //   const formData = new FormData()
  //   formData.append('Start', String(0))
  //   formData.append('Length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

  //   if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
  //   if (!isEmpty(payload.CompanyId)) formData.append('CompanyId', payload.CompanyId)
  //   if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
  //   if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
  //   if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
  //   if (!isEmpty(payload.MinValidTo)) formData.append('MinValidTo', payload.MinValidTo)
  //   if (!isEmpty(payload.MaxValidTo)) formData.append('MaxValidTo', payload.MaxValidTo)
  //   if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
  //   if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)
  //   if (type && !isEmpty(type)) formData.append('Content-Type', type)

  //   downloadPromoCode(formData)
  // }
//   const onCreate = () => setDialogCreate(true)

  useEffect(() => {
    onResync()
  }, [payload])

  const tableHeadTitles = [
    'Subscription Plan Name',
    'Scheme Type',
    'Price',
    'Car Model',
    'Status'
  ]
  const numbers = GLOBAL.tableNumber(payload.Start)
  const openAnchor = Boolean(anchor)
  const totalPage = promoCode.data && GLOBAL.tableTotalPage(promoCode.data.recordsTotal)

  return (
    <>
      <Content
        title='Notification List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        // onDownload={onDownload}
        // downloadLoading={download.isLoading}
        // useDownloadDropdown
        // onAdditional={onCreate}
        additionalTitle='Create New Plan'
        additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={promoCode.data && promoCode.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={promoCode.isLoading}
      >
        {promoCode.isSuccess &&
          promoCode.data &&
          promoCode.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='MoreHoriz' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  {/* <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem> */}

                  <MenuModalItem
                    title={table.code}
                    onClick={() => onRemoveAnchor(() => deletePromoCode(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {/* {dialogUpdate && menu == index && (
                  <Edit
                    id={table.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )} */}
              </TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell>{table.rewardType || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.voucherName || '-'}</TableCell>
              <TableCell>{table.totalVoucherQuantity || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>
      {/* {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />} */}
      {promoCode.isLoading && <Loading />}
      <Notification
        open={!promoCode.isLoading && !promoCode.isUninitialized && !promoCode.isSuccess}
        onClose={() => (promoCode.isError ? promoCode.reset() : location.reload())}
        isError={Boolean(promoCode.error) && promoCode.isError}
        message={GLOBAL.returnExceptionMessage(promoCode.isError, promoCode.error as ErrorProps)}
      />
      <Notification
        open={!deletePromo.isLoading && !deletePromo.isUninitialized}
        onClose={() => (deletePromo.isError ? deletePromo.reset() : location.reload())}
        isError={Boolean(deletePromo.error) && deletePromo.isError}
        message={GLOBAL.returnExceptionMessage(
          deletePromo.isError,
          deletePromo.error as ErrorProps
        )}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomNumber(randomNumber + 1)
          setFilter({
            CompanyId: '',
            RewardType: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidTo: '',
            MaxValidTo: '',
            MinCreatedAt: '',
            MaxCreatedAt: ''
          })
          // setDate({
          //   minValidFrom: null,
          //   maxValidFrom: null,
          //   minValidUntil: null,
          //   maxValidUntil: null,
          //   minCreatedDate: null,
          //   maxCreateDate: null
          // })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            CompanyId: '',
            RewardType: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidTo: '',
            MaxValidTo: '',
            MinCreatedAt: '',
            MaxCreatedAt: '',
            Status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Autocomplete
          options={(partnerDropdown && partnerDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getPartnerDropdownList()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              CompanyId: (partner && String(partner.id)) || ''
            })
          }
          value={
            (partnerDropdown &&
              partnerDropdown.data &&
              partnerDropdown.data.find((e) => String(e.id) == filter.CompanyId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='partnerId'
              label='Subscription Plan'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {partnerDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          options={(partnerDropdown && partnerDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getPartnerDropdownList()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              CompanyId: (partner && String(partner.id)) || ''
            })
          }
          value={
            (partnerDropdown &&
              partnerDropdown.data &&
              partnerDropdown.data.find((e) => String(e.id) == filter.CompanyId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='partnerId'
              label='Car Model'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {partnerDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Typography>Price Range</Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
          <TextField
            id='MemberId'
            variant='outlined'
            label='Price From'
            type='number'
            // InputProps={{
            //   inputProps: {
            //     min: filter.MaxValidTo
            //   }
            // }}
            sx={{ width: "260px" }}
            value={filter.MaxValidTo}
            onChange={(event) => setFilter({ ...filter, MaxValidTo: event.target.value })}
            fullWidth
          />
          <TextField
            id='MemberId'
            variant='outlined'
            label='Price To'
            type='number'
            InputProps={{
              inputProps: {
                max: 100, min: 10 
              }
            }}
            sx={{ width: "260px" }}
            value={filter.CompanyId}
            onChange={(event) => setFilter({ ...filter, CompanyId: event.target.value })}
            fullWidth
          />
        </div>
        <Typography>Period</Typography>
        <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MinValidFrom ? dayjs(filter.MinValidFrom) : null}
              minDate={dayjs(filter.MinValidFrom)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MinValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
              }
              label='Start Date'
              format='DD-MM-YYYY'
              sx={{ width: "260px" }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={filter.MaxValidFrom ? dayjs(filter.MaxValidFrom) : null}
              minDate={dayjs(filter.MinValidFrom)}
              onChange={(event: Dayjs | null) =>
                setFilter({ ...filter, MaxValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
              }
              label='End Date'
              format='DD-MM-YYYY'
              sx={{ width: "260px" }}
            />
          </LocalizationProvider>
        </div>
        <Autocomplete
          options={(partnerDropdown && partnerDropdown.data) || []}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option && value ? option.id == value.id : false
          }
          onOpen={() => getPartnerDropdownList()}
          onChange={(_, partner) =>
            setFilter({
              ...filter,
              CompanyId: (partner && String(partner.id)) || ''
            })
          }
          value={
            (partnerDropdown &&
              partnerDropdown.data &&
              partnerDropdown.data.find((e) => String(e.id) == filter.CompanyId)) ||
            undefined
          }
          ListboxProps={GlobalStyle.ListBox}
          renderOption={(props, item) => (
            <li {...props} key={item.id}>
              {item.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              name='partnerId'
              label='Status'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {partnerDropdown.isLoading && <CircularProgress color='inherit' size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Filter>
    </>
  )
}

export default NotificationBluelinkList
