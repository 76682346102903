import { useState } from 'react'
// import type { SetStateAction } from 'react'
import { isEmpty } from 'lodash'
import * as yup from 'yup'
import { Autocomplete, Box, Chip, CircularProgress, Container, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import Dialog from 'components/dialog/dialog'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import FORMIK from 'modules/formik'
import { useCreateListBannerMutation, useGetEventDropdownMutation } from 'store/serviceBookingEventBanner'
import type { PartialBannerCreateDataProps, PartialBannerCreateProps } from 'modules/partial'
import { useGetMemberTypeDropdownMutation } from 'store/dropdown'
import type {
  CallbackHandler, DetailStateProps,
  ErrorProps,
  MemberTypeDropDown
} from 'modules/types'
import BackdropImage from 'components/backdropImage/backdropImage'
import GlobalStyle from 'modules/styles'
import DialogStyle from './style'


const Create = ({ open, onClose }: DetailStateProps) => {
  if (!open) return <></>

  const [getEventDropdown, eventDropdown] = useGetEventDropdownMutation()
  const [createBannerList, create] = useCreateListBannerMutation()
  const [getMemberTypeDropdown, memberType] = useGetMemberTypeDropdownMutation()

  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null>(null)
  const [previewBackdrop, setPreviewBackdrop] = useState<boolean>(false)
  const [userType, setUserType] = useState<MemberTypeDropDown[]>([])

  const scheme = yup.object<PartialBannerCreateProps>({
    Image: yup.string().required('BannerImage is required'),
    data: yup.object<PartialBannerCreateDataProps>({
      EventId: yup.string().required('EventId is required'),
      ValidFrom: yup.string().required('ValidFrom is required'),
      ValidUntil: yup.string().required('ValidUntil is required'),
      Status: yup.string().required('Status Type is required')
    })
  })

  const initialValues: PartialBannerCreateProps = {
    Image: {} as File,
    data: {
      bannerImage: '',
      EventId: '',
      ValidFrom: '',
      ValidUntil: '',
      Status: ''
    }
  }

  const formik = FORMIK.useFormFormik<PartialBannerCreateProps>(
    scheme,
    initialValues,
    (values) => {
      const formData = new FormData()

      const data = {
        ...values.data
      }

      if (values.Image) formData.append('Image', values.Image)
      formData.append('data', JSON.stringify(data))
      createBannerList(formData)
    }
  )

  const fileOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    callback: CallbackHandler<string | ArrayBuffer | null>
  ) => {
    const files = e && e.target && e.target.files
    const notEmpty = files && files.length > 0 && !isEmpty(files)

    if (notEmpty) {
      const reader = new FileReader()

      reader.onload = () => callback(reader.result)
      reader.readAsDataURL(files[0])
      formik.setFieldValue('data.bannerImage', files[0].name)
      formik.setFieldValue(field, files[0])
      return
    }

    formik.setFieldValue(field, {} as File)
    return callback(null)
  }

  const error = formik.errors && (formik.errors.data as unknown as PartialBannerCreateDataProps)
  const touched = formik.touched && (formik.touched as PartialBannerCreateDataProps)
  const requestToken = formik.values.data

  // const arraAll =
  //   {
  //     id: 6,
  //     key: "All",
  //     name: 'All',
  //   };

  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  // const newArray: SetStateAction<MemberTypeDropDown[]> = [];
  // const handleChanges = (value: MemberTypeDropDown[]) => {
  //   const isian = memberType.data;
  //   for (let i = 0; i < value.length; i++) {
  //     if (value[i].id === 1) {
  //       isian?.forEach(item => {
  //         newArray.push(item);
  //         setUserType(newArray)
  //       })
  //       setIsDisabled(true)
  //     } else if (value[i].id === 2) {
  //       console.log('masukk 2');
  //       // setUserType([arraAll, ...value]);
  //       setUserType(value.filter(item => item.name !== 'White'));
  //       // setUserType(value)
  //       setIsDisabled(false)
  //     } else if (value[i].id === 3) {
  //       console.log('masukk 3');
  //       setUserType(value.filter(item => item.name !== 'White'));
  //       setIsDisabled(false)
  //     }else if (value[i].id === 4) {
  //       console.log('masukk 4');
  //       setUserType(value.filter(item => item.name !== 'White'));
  //       setIsDisabled(false)
  //     }
  //     else if (value[i].id === 5) {
  //       console.log('masukk 5');
  //       setUserType(value.filter(item => item.name !== 'White'));
  //       setIsDisabled(false)
  //     }
  //   }
  // };

  const handleChange = (value: MemberTypeDropDown[]) => {
    const isian = memberType.data || [];
    
    const selectedIds = value.map(item => item.id);
    
    if (selectedIds.includes(1)) {
      setUserType(isian);
      setIsDisabled(true);
    } else {
      const filteredValue = value.filter(item => item.name !== 'White');
      setUserType(filteredValue);
      setIsDisabled(false);
    }
  };
  // console.log('validFrom', payloadDate.validFrom);
  // console.log('formik', formik);
  // const disabled =
  //     !formik.isValid || isEmpty(previewImage)

  console.log('====================================');
  console.log(userType);
  console.log('====================================');
  
  return (
    <>
      <Dialog
        title='Create New Plan'
        open={open}
        onCancel={onClose}
        onSubmit={() => formik.handleSubmit()}
        loading={create.isLoading}
        isDisabled={!formik.isValid}
      // isDisabled={disabled}
      >
        <Container {...DialogStyle.Container}>
          <TextField
            {...DialogStyle.textField}
            id='code'
            variant='outlined'
            label='Subscription Plan Name EN-US'
            onChange={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
            onBlur={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
            value={''}
            // error={formik.touched && formik.touched.code && Boolean(formik.errors.code)}
            // helperText={
            //   formik.touched && formik.touched.code && formik.errors && formik.errors.code
            // }
            fullWidth
          />
          <TextField
            {...DialogStyle.textField}
            id='code'
            variant='outlined'
            label='Subscription Plan Name ID'
            onChange={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
            onBlur={(e) => formik.setFieldValue('code', GLOBAL.capitalize(e.target.value))}
            value={''}
            // error={formik.touched && formik.touched.code && Boolean(formik.errors.code)}
            // helperText={
            //   formik.touched && formik.touched.code && formik.errors && formik.errors.code
            // }
            fullWidth
          />
          <Autocomplete
            options={(eventDropdown && eventDropdown.data) || []}
            getOptionLabel={(list) => list.text}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.value == value.value : false
            }
            onOpen={() => getEventDropdown()}
            onChange={(_, id) => formik.setFieldValue('data.EventId', id && id.value)}
            ListboxProps={GlobalStyle.ListBox}
            renderOption={(props, item) => (
              <li {...props} key={item.value}>
                {item.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name='EventId'
                label='Scheme Type'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {eventDropdown.isLoading && (
                        <CircularProgress color='inherit' size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidFrom) : undefined}
              onChange={(e: Dayjs | null) => {
                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                formik.setFieldValue('data.ValidFrom', newDate)
                // setPayloadDate({ ...payloadDate, validFrom: newDate })
                // setDate((prevState) => ({ ...prevState, validFrom: e }))
              }}
              label='Valid From'
              format='DD-MM-YYYY'
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
            <DatePicker
              value={requestToken && requestToken.ValidUntil ? dayjs(requestToken.ValidUntil) : undefined}
              minDate={requestToken && requestToken.ValidFrom ? dayjs(requestToken.ValidFrom) : undefined}
              onChange={(e: Dayjs | null) => {

                const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                formik.setFieldValue('data.ValidUntil', newDate)
                // setPayloadDate({ ...payloadDate, validUntil: newDate })
                // setDate((prevState) => ({ ...prevState, validUntil: e }))
              }}
              label='Valid Until'
              format='DD-MM-YYYY'
            />
          </LocalizationProvider>
          <FormControl fullWidth>
            <InputLabel htmlFor='electricityFee'>Electricity Fee</InputLabel>
            <OutlinedInput
              id='electricityFee'
              label='Electricity Fee'
              type='number'
              // value={formik.values.electricityFee && formik.values.electricityFee.toString()}
              value={''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // error={
              //   formik.touched &&
              //   formik.touched.electricityFee &&
              //   Boolean(formik.errors.electricityFee)
              // }
              inputProps={{ autoComplete: 'off' }}
              onKeyDown={(event) => {
                if (/^[.,\-\b]+$/.test(event.key)) event.preventDefault()
              }}
              startAdornment={<InputAdornment position='start'>Rp</InputAdornment>}
              endAdornment={
                <InputAdornment position='end'>
                  {/* / {formik.values.electricityFeePerUnit} */}
                </InputAdornment>
              }
            />
            {/* {formik.touched && formik.touched.electricityFee && formik.errors && (
              <FormHelperText error id='electricityFee'>
                {formik.errors.electricityFee}
              </FormHelperText>
            )} */}
          </FormControl>
          <Autocomplete
            fullWidth
            sx={{ marginBottom: '10px' }}
            multiple
            id='fixed-tags-demo'
            value={userType}
            onOpen={() => getMemberTypeDropdown()}
            isOptionEqualToValue={(option, value) =>
              option && value ? option.name == value.name : false
            }
            // onChange={(event, newValue) => {
            //   setUserType(newValue)
            // }}
            onChange={(event, newValue) => {
              setUserType(newValue);
              handleChange(newValue);
            }}
            options={(memberType && memberType.data) || []}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.name} {...getTagProps({ index })}
                  key={index}
                  disabled={isDisabled}
                />
              ))
            }
            renderInput={(params) => <TextField {...params} label='Car Model' />}
          />
          <TextField
            id='Image'
            label='Banner Image'
            type='file'
            placeholder='Image'
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: 'image/png, image/gif, image/jpeg' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              fileOnChange(e, 'Image', (value) => setPreviewImage(value))
            }
            onBlur={formik.handleBlur}
            fullWidth
          />
          {previewImage && (
            <Grid item xs={12}>
              <Typography {...DialogStyle.Title}>Banner Image</Typography>
              <Box {...DialogStyle.Image}>
                <BackdropImage
                  open={previewBackdrop}
                  onOpen={() => setPreviewBackdrop(true)}
                  onClose={() => setPreviewBackdrop(false)}
                >
                  <img
                    src={previewImage as string | undefined}
                    alt='Banner Image'
                    width='100%'
                    height='100%'
                  />
                </BackdropImage>
              </Box>
            </Grid>
          )}
          <FormControl fullWidth>
            <InputLabel id='Status'>Status</InputLabel>
            <Select
              labelId='Status'
              id='Status'
              variant='outlined'
              label='Voucher Type *'
              value={formik.values.data.Status}
              onChange={(event) => {
                formik.setFieldValue('data.Status', '')
                formik.setFieldValue('data.Status', event.target.value)
              }}
              error={
                touched && Boolean(touched.Status) && error && Boolean(error.Status)
              }
            >
              <MenuItem value='Enabled'>Enabled</MenuItem>
              <MenuItem value='Disabled'>Disabled</MenuItem>
            </Select>
          </FormControl>
        </Container>
      </Dialog>

      <Notification
        open={!create.isLoading && !create.isUninitialized}
        onClose={() => (create.isError ? create.reset() : location.reload())}
        isError={Boolean(create.error) && create.isError}
        message={GLOBAL.returnExceptionMessage(create.isError, create.error as ErrorProps)}
      />
    </>
  )
}

export default Create
