import { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { VisibilityOff, Visibility, Person } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Container,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  FormHelperText
} from '@mui/material'
import * as yup from 'yup'
import { useSelector } from 'react-redux'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useLoginUserMutation } from 'store/user'
import type { ErrorProps, UserProps } from 'modules/types'
import type { PartialUserProps } from 'modules/partial'
import LoginStyle from './style'

const Login = () => {
  const navigate = useNavigate()
  const state = useSelector((state: { user: UserProps }) => state)

  const [loginUser, result] = useLoginUserMutation()
  const [visibility, setVisibility] = useState<boolean>(false)
  const [initialValues] = useState({
    username: '',
    password: ''
  })

  const scheme = yup.object<PartialUserProps>({
    username: yup.string().required('Email is required'),
    password: yup.string().required('Password is required')
  })

  const passResultData = () => ({
    state: {
      phoneNumber: result.data?.user.phone,
      requestOtp: process.env.REACT_APP_BYPASS_OTP === 'false' ? false : true,
      loginResponse: result.data
    }
  })

  useEffect(() => {
    if (!isEmpty(state.user.token)) return navigate('/')
    if (!result.isLoading && result.isSuccess) {
      return navigate('/login-otp', passResultData())
    }
  }, [result, state])

  const onCloseNotification = () => {
    if (!result.isLoading && result.isSuccess) {
      navigate('/login-otp', passResultData())
    }
  }

  return (
    <>
      <Container maxWidth={false} disableGutters>
        <Box {...LoginStyle.Wrapper}>
          <Formik
            initialValues={initialValues}
            validationSchema={scheme}
            enableReinitialize
            validateOnMount
            onSubmit={(values, { setSubmitting }) =>
              loginUser(values).then(() => setSubmitting(false))
            }
          >
            {({
              values,
              touched,
              errors,
              isValid,
              isSubmitting,
              handleBlur,
              handleChange,
              handleSubmit
            }) => (
              <form onSubmit={handleSubmit} {...LoginStyle.Card}>
                <img src='/logo-bluelink.png' alt='Logo' {...LoginStyle.Logo} />
                <FormControl fullWidth>
                  <InputLabel htmlFor='username'>Email</InputLabel>
                  <OutlinedInput
                    id='username'
                    label='Email'
                    type='email'
                    autoComplete='email'
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched && touched.username && Boolean(errors.username)}
                    endAdornment={
                      <IconButton aria-label='email / username' edge='end'>
                        <Person />
                      </IconButton>
                    }
                  />
                  {touched && touched.username && errors && (
                    <FormHelperText error id='username'>
                      {errors.username}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel htmlFor='password'>Password</InputLabel>
                  <OutlinedInput
                    id='password'
                    label='Password'
                    type={visibility ? 'text' : 'password'}
                    autoComplete='current-password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched && touched.password && Boolean(errors.password)}
                    endAdornment={
                      <IconButton
                        aria-label='password visibility'
                        onClick={() => setVisibility(!visibility)}
                        edge='end'
                      >
                        {visibility ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    }
                  />
                  {touched && touched.password && errors && (
                    <FormHelperText error id='password'>
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <LoadingButton disabled={!isValid} loading={isSubmitting} {...LoginStyle.Button}>
                  Sign In
                </LoadingButton>
                <Box {...LoginStyle.WrapperVersion}>Version 1.2.111</Box>
              </form>
            )}
          </Formik>
        </Box>
      </Container>

      <Notification
        open={!result.isLoading && !result.isUninitialized}
        onClose={onCloseNotification}
        isError={Boolean(result.error) && result.isError}
        message={GLOBAL.returnExceptionMessage(result.isError, result.error as ErrorProps)}
      />
    </>
  )
}

export default Login
