import {
  Box,
  Button,
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Container,
  Grid,
  // TextField,
  Typography
} from '@mui/material'
import { EventAvailable, ExpandMoreOutlined } from '@mui/icons-material'
import Drawer from 'components/drawer/detail'
import Loading from 'components/loading/loading'
import type { DetailStateProps } from 'modules/types'
import { useGetVoucherUsageDetailQuery } from 'store/voucherUsage'
import DrawerStyle from './style'


const Detail = ({ open, id, onClose }: DetailStateProps & { id: number }) => {
  if (!open) return <Drawer open={open} onClose={onClose} />

  const { data, isFetching, isLoading } = useGetVoucherUsageDetailQuery(id)

  const loading = !data && (isLoading || isFetching)

  // const createdBy = (data && data.createdBy) || '-'
  // const createdAt = (data && data.createdAt) || '-'
  // const modifiedBy = (data && data.modifiedBy) || '-'
  // const modifiedAt = (data && data.modifiedAt) || '-'

  // const gridBasicLabel = ['Created By', 'Created At', 'Modified By', 'Modified At']
  // const gridBasicValue = [createdBy, createdAt, modifiedBy, modifiedAt]

  return (
    <>
      <Drawer open={open} title='Detail Active Subscription' onClose={onClose}>
        <Container {...DrawerStyle.Container}>
          {loading && <Loading />}
          {!loading && (
            <>
              <Grid container spacing={2} style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: 15
              }}>
                <Grid spacing={2}
                  style={{
                    backgroundColor: '#ECE9E6',
                    height: 180,
                    marginBottom: 16
                  }}>
                  <Grid style={{ padding: 12 }}>
                    <Grid style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: 12
                    }}>
                      <ExpandMoreOutlined style={{ height: 42, width: 42 }} />
                      <Grid style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 12
                      }}>
                        <Typography style={{ fontSize: 16, fontWeight: 400 }}>Plan</Typography>
                        <Typography style={{ fontSize: 18, fontWeight: 600 }}>Eco Drive - Yearly</Typography>
                      </Grid>
                    </Grid>
                    <Grid style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginBottom: 12
                    }}>
                      <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Subscription Periode</Typography>
                      <Typography style={{ fontSize: 16, fontWeight: 600, color: 'black' }}>Jan 01, 2025 - Dec 31, 2025</Typography>
                    </Grid>
                    <Grid style={{
                      height: 60,
                      backgroundColor: '#FFFFFF',
                      paddingTop: 8,
                      paddingRight: 12,
                      paddingBottom: 8,
                      paddingLeft: 12,
                      border: 1,
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                      <EventAvailable style={{ height: 40, width: 40 }} />
                      <Grid style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 12
                      }}>
                        <Typography style={{ fontSize: 14, fontWeight: 400, color: 'black' }}>Auto Renewal</Typography>
                        {/* <Typography style={{fontSize: 12, fontWeight: 400, color: '#737373'}}>Next Payment Jan 01, 2026</Typography> */}
                        <Typography style={{ fontSize: 12, fontWeight: 400, color: 'red' }}>Auto Renewal has been stop by user on Jan 31, 2025</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography style={{ fontSize: 16, fontWeight: 600 }}>Transaction Summary</Typography>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Transaction ID</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>24524543543</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Transaction Date</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>Jan 01, 2025 10:11:30</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Invoice ID</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>24524543543</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Registered Owner</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>24524543543</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Email</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>24524543543</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Auto Renewal</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>24524543543</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>VIN</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>12333339393939</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Car Model</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>Ionic 5</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Payment Method</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>BCA Virtual Account</Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Typography style={{ marginTop: 16, fontSize: 16, fontWeight: 600 }}>Payment Information</Typography>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Price</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>Rp 2,000,000</Typography>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Discount</Typography>
                    <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <Typography style={{ fontSize: 16, fontWeight: 600 }}>-Rp 500,000</Typography>
                      <Typography style={{ fontSize: 14, fontWeight: 400 }}>Discount End Year</Typography>
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>Promo Code</Typography>
                    <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <Typography style={{ fontSize: 16, fontWeight: 600 }}>-Rp 300,000</Typography>
                      <Typography style={{ fontSize: 14, fontWeight: 400 }}>ABCACFG</Typography>
                    </Grid>
                  </Grid>
                  <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#737373' }}>VAT</Typography>
                    <Typography style={{ fontSize: 16, fontWeight: 600 }}>Rp 120,000</Typography>
                  </Grid>
                </Grid>
                <div>
                  <hr />
                </div>
                <Grid style={{ marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography style={{ fontSize: 16, fontWeight: 600 }}>Total  (Including Tax)</Typography>
                  <Typography style={{ fontSize: 16, fontWeight: 600 }}>Rp 120,000</Typography>
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' , marginTop: 3}}>
                <Button
                  variant='outlined'
                  style={{
                    backgroundColor: 'white',
                    color: '#002C5F',
                    height: 48,
                    width: 207
                  }}
                  onClick={() => {
                    console.log('diklik');
                  }}
                >
                  Stop Auto Renewal
                </Button>
                <Button
                  variant='contained'
                  style={{
                    backgroundColor: '#002C5F',
                    color: 'white',
                    height: 48,
                    width: 207,
                    marginLeft:24
                  }}
                  onClick={() => {
                    console.log('diklik');
                  }}
                >
                  Download Invoice
                </Button>
              </Box>
            </>
          )}
        </Container>
      </Drawer>
    </>
  )
}

export default Detail
