import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {
  Autocomplete,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import Filter from 'components/filter/filter'
import Content from 'components/content/content'
import type { ErrorProps, FilterDatePromoCodeProps } from 'modules/types'
import Icon from 'components/icon'
import MenuModalItem from 'components/menuItem/menuItem'
import GlobalStyle from 'modules/styles'
import {
  useDeletePromoCodeMutation,
  useDownloadPromoCodeMutation,
  useGetListPromoCodeMutation
} from 'store/promoCode'
import type { PartialPromoCodeBatchProps1 } from 'modules/partial'
import CONSTANT from 'modules/constant'
import Loading from 'components/loading/loading'
import Notification from 'components/notification'
import GLOBAL from 'modules/global'
import { useDropDownPromoCodeBatchRewardMutation } from 'store/promoCodeBatch'
// import Create from './create/create'
// import Edit from './edit/edit'
import Detail from './detail/detail'

const FreeSubscriptionBluelinkList = () => {
  const [getListPromoCode, promoCode] = useGetListPromoCodeMutation()
  const [dropDownPromoCodeBatchReward, dropdownReward] = useDropDownPromoCodeBatchRewardMutation()
  const [downloadPromoCode, download] = useDownloadPromoCodeMutation()
  const [deletePromoCode, deletePromo] = useDeletePromoCodeMutation()
  const [date, setDate] = useState<FilterDatePromoCodeProps>({
    minValidFrom: null,
    maxValidFrom: null,
    minValidUntil: null,
    maxValidUntil: null,
    minCreatedDate: null,
    maxCreateDate: null
  })
  const [filter, setFilter] = useState({
    Code: '',
    RewardType: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidTo: '',
    MaxValidTo: '',
    MinCreatedAt: '',
    MaxCreatedAt: ''
  })
  const [payload, setPayload] = useState<PartialPromoCodeBatchProps1>({
    Start: 0,
    Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
    Search: '',
    Code: '',
    RewardType: '',
    MinValidFrom: '',
    MaxValidFrom: '',
    MinValidTo: '',
    MaxValidTo: '',
    MinCreatedAt: '',
    MaxCreatedAt: '',
    Status: ''
  })
  const [dialogFilter, setDialogFilter] = useState<boolean>(false)
  // const [dialogCreate, setDialogCreate] = useState<boolean>(false)
  // const [dialogUpdate, setDialogUpdate] = useState<boolean>(false)
  const [drawerDetail, setDrawerDetail] = useState<boolean>(false)
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [menu, setMenu] = useState<null | number>(null)
  const [randomNumber, setRandomNumber] = useState<number>(0)

  const onAction = (event: React.MouseEvent<HTMLElement>, index: null | number) => {
    setAnchor(event.currentTarget)
    setMenu(index)
  }

  const onRemoveAnchor = async (callback?: CallableFunction) => {
    setAnchor(null)
    callback && callback(callback)
  }

  const onSearch = (value: string) => setPayload({ ...payload, Start: 0, Search: value })
  const onChangePage = (value: number) => setPayload({ ...payload, Start: value - 1 })
  const onChangePerPage = (value: number) => setPayload({ ...payload, Length: value })
  const onResync = () => {
    const formData = new FormData()
    formData.append('Start', payload.Start.toString())
    formData.append('Length', payload.Length.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Code)) formData.append('Code', payload.Code)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidTo)) formData.append('MinValidTo', payload.MinValidTo)
    if (!isEmpty(payload.MaxValidTo)) formData.append('MaxValidTo', payload.MaxValidTo)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)

    getListPromoCode(formData)
  }
  const onFilter = (state: boolean) => setDialogFilter(state)
  const onDownload = (type?: string) => {
    const formData = new FormData()
    formData.append('Start', String(0))
    formData.append('Length', CONSTANT.DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD.toString())

    if (!isEmpty(payload.Search)) formData.append('Search', payload.Search)
    if (!isEmpty(payload.Code)) formData.append('Code', payload.Code)
    if (!isEmpty(payload.RewardType)) formData.append('RewardType', payload.RewardType)
    if (!isEmpty(payload.MinValidFrom)) formData.append('MinValidFrom', payload.MinValidFrom)
    if (!isEmpty(payload.MaxValidFrom)) formData.append('MaxValidFrom', payload.MaxValidFrom)
    if (!isEmpty(payload.MinValidTo)) formData.append('MinValidTo', payload.MinValidTo)
    if (!isEmpty(payload.MaxValidTo)) formData.append('MaxValidTo', payload.MaxValidTo)
    if (!isEmpty(payload.MinCreatedAt)) formData.append('MinCreatedAt', payload.MinCreatedAt)
    if (!isEmpty(payload.MaxCreatedAt)) formData.append('MaxCreatedAt', payload.MaxCreatedAt)
    if (type && !isEmpty(type)) formData.append('Content-Type', type)

    downloadPromoCode(formData)
  }
  // const onCreate = () => setDialogCreate(true)

  useEffect(() => {
    onResync()
  }, [payload])

  const tableHeadTitles = [
    'User ID',
    'VIN',
    'Exp Date',
    'Email',
    'Grace Period Start',
    'Grace Period End',
    'Status'
  ]
  const numbers = GLOBAL.tableNumber(payload.Start)
  const openAnchor = Boolean(anchor)
  const totalPage = promoCode.data && GLOBAL.tableTotalPage(promoCode.data.recordsTotal)

  return (
    <>
      <Content
        title='Free Subscription Bluelink List'
        tableHeadTitles={tableHeadTitles}
        onSearch={onSearch}
        onResync={onResync}
        onFilter={() => onFilter(true)}
        onDownload={onDownload}
        downloadLoading={download.isLoading}
        useDownloadDropdown
        // onAdditional={onCreate}
        // additionalTitle='Create New Promo Code'
        // additionalPrefixIcon='Create'
        pagination={onChangePage}
        onChangePerPage={onChangePerPage}
        totalPage={totalPage}
        totalRecords={promoCode.data && promoCode.data.recordsTotal}
        page={payload.Start + 1}
        isLoading={promoCode.isLoading}
        useAdjustRenewalAction={true}
      >
        {promoCode.isSuccess &&
          promoCode.data &&
          promoCode.data.data.map((table, index) => (
            <TableRow key={index}>
              <TableCell>{numbers[index] + 1}</TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='VisibilityOutlined' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  {/* <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem> */}

                  <MenuModalItem
                    title={table.code}
                    onClick={() => onRemoveAnchor(() => deletePromoCode(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {/* {dialogUpdate && menu == index && (
                  <Edit
                    id={table.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )} */}
              </TableCell>
              <TableCell>
                <IconButton
                  id={`button-${index}-${table.id}`}
                  aria-haspopup='true'
                  aria-controls={openAnchor ? `menu-${index}-${table.id}` : undefined}
                  aria-expanded={openAnchor ? 'true' : undefined}
                  onClick={(event) => onAction(event, index)}
                >
                  <Icon icon='BorderColorOutlined' />
                </IconButton>
                <Menu
                  id={`menu-${index}-${table.id}`}
                  aria-labelledby={`button-${index}-${table.id}`}
                  anchorEl={anchor}
                  open={openAnchor && menu == index}
                  onClose={() => onRemoveAnchor(() => setMenu(null))}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem onClick={() => onRemoveAnchor(() => setDrawerDetail(true))}>
                    View
                  </MenuItem>
                  {/* <MenuItem onClick={() => onRemoveAnchor(() => setDialogUpdate(true))}>
                    Edit
                  </MenuItem> */}

                  <MenuModalItem
                    title={table.code}
                    onClick={() => onRemoveAnchor(() => deletePromoCode(table.id))}
                  >
                    <Typography {...GlobalStyle.ErrorTypography}>Delete</Typography>
                  </MenuModalItem>
                </Menu>
                <Detail
                  id={table.id}
                  open={drawerDetail && menu == index}
                  onClose={() => setDrawerDetail(false)}
                />
                {/* {dialogUpdate && menu == index && (
                  <Edit
                    id={table.id}
                    open={dialogUpdate && menu == index}
                    onClose={() => setDialogUpdate(false)}
                  />
                )} */}
              </TableCell>
              <TableCell>{table.code || '-'}</TableCell>
              <TableCell>{table.name || '-'}</TableCell>
              <TableCell {...GlobalStyle.EllipsisTable}>{table.voucherName || '-'}</TableCell>
              <TableCell>{table.totalVoucherQuantity || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDate(table.validFrom) || '-'}</TableCell>
              <TableCell>{GLOBAL.formatDate(table.validTo) || '-'}</TableCell>
              <TableCell>{table.rewardType || '-'}</TableCell>
            </TableRow>
          ))}
      </Content>
      {/* {dialogCreate && <Create open={dialogCreate} onClose={() => setDialogCreate(false)} />} */}
      {promoCode.isLoading && <Loading />}
      <Notification
        open={!promoCode.isLoading && !promoCode.isUninitialized && !promoCode.isSuccess}
        onClose={() => (promoCode.isError ? promoCode.reset() : location.reload())}
        isError={Boolean(promoCode.error) && promoCode.isError}
        message={GLOBAL.returnExceptionMessage(promoCode.isError, promoCode.error as ErrorProps)}
      />
      <Notification
        open={!deletePromo.isLoading && !deletePromo.isUninitialized}
        onClose={() => (deletePromo.isError ? deletePromo.reset() : location.reload())}
        isError={Boolean(deletePromo.error) && deletePromo.isError}
        message={GLOBAL.returnExceptionMessage(
          deletePromo.isError,
          deletePromo.error as ErrorProps
        )}
      />
      <Filter
        open={dialogFilter}
        title='Filter'
        isSubmitDisabled={!GLOBAL.isFilterContainValue(filter)}
        onClose={() => onFilter(false)}
        onCancel={() => onFilter(false)}
        onReset={() => {
          setRandomNumber(randomNumber + 1)
          setFilter({
            Code: '',
            RewardType: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidTo: '',
            MaxValidTo: '',
            MinCreatedAt: '',
            MaxCreatedAt: ''
          })
          setDate({
            minValidFrom: null,
            maxValidFrom: null,
            minValidUntil: null,
            maxValidUntil: null,
            minCreatedDate: null,
            maxCreateDate: null
          })
          setPayload({
            Start: 0,
            Length: CONSTANT.DEFAULT_PAGINATION_PER_PAGE,
            Search: '',
            Code: '',
            RewardType: '',
            MinValidFrom: '',
            MaxValidFrom: '',
            MinValidTo: '',
            MaxValidTo: '',
            MinCreatedAt: '',
            MaxCreatedAt: '',
            Status: ''
          })
          onFilter(false)
        }}
        onSubmit={() => {
          setPayload({
            ...payload,
            ...filter
          })
          onFilter(false)
        }}
      >
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.minValidFrom}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter((prevState) => ({ ...prevState, MinValidFrom: newDate }))
                  setDate((prevState) => ({ ...prevState, minValidFrom: e }))
                }}
                label='Min Valid From'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.maxValidFrom}
                sx={{ width: '100%' }}
                minDate={dayjs(filter.MinValidFrom)}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter((prevState) => ({ ...prevState, MaxValidFrom: newDate }))
                  setDate((prevState) => ({ ...prevState, maxValidFrom: e }))
                }}
                label='Max Valid From'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.minValidUntil}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter((prevState) => ({ ...prevState, MinValidTo: newDate }))
                  setDate((prevState) => ({ ...prevState, minValidUntil: e }))
                }}
                label='Min Valid Until'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.maxValidUntil}
                sx={{ width: '100%' }}
                minDate={dayjs(filter.MinValidTo)}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter((prevState) => ({ ...prevState, MaxValidTo: newDate }))
                  setDate((prevState) => ({ ...prevState, maxValidUntil: e }))
                }}
                label='Max Valid Until'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.minCreatedDate}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter((prevState) => ({ ...prevState, MinCreatedAt: newDate }))
                  setDate((prevState) => ({ ...prevState, minCreatedDate: e }))
                }}
                label='Min Created Date'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
              <DatePicker
                value={date.maxCreateDate}
                minDate={dayjs(filter.MinCreatedAt)}
                sx={{ width: '100%' }}
                onChange={(e) => {
                  const newDate = (!isEmpty(e) && e?.format('YYYY-MM-DD')) || ''
                  setFilter((prevState) => ({ ...prevState, MaxCreatedAt: newDate }))
                  setDate((prevState) => ({ ...prevState, maxCreateDate: e }))
                }}
                label='Max Created Date'
                format='DD-MM-YYYY'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <Autocomplete
              key={`Status` + randomNumber}
              options={(dropdownReward && dropdownReward.data) || []}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, value) =>
                option && value ? option.value == value.value : false
              }
              onOpen={() => dropDownPromoCodeBatchReward()}
              onChange={(_, reward) => {
                if (!isEmpty(reward)) setFilter({ ...filter, RewardType: reward?.text })
              }}
              value={
                (dropdownReward &&
                  dropdownReward.data &&
                  dropdownReward.data.find((e) => e.text == filter.RewardType)) ||
                undefined
              }
              ListboxProps={GlobalStyle.ListBox}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='Reward'
                  label='Reward'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {dropdownReward.isLoading && <CircularProgress color='inherit' size={20} />}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <TextField
              id='Code'
              variant='outlined'
              label='Promo Code'
              value={filter.Code}
              onChange={(event) => setFilter({ ...filter, Code: event.target.value })}
              fullWidth
            />
          </Grid>
        </Grid>
      </Filter>
    </>
  )
}

export default FreeSubscriptionBluelinkList
