
import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import HighchartsReact from "highcharts-react-official";
// import ReactHighcharts from 'react-highcharts';
import * as Highcharts from 'highcharts';
import highchartsMore from "highcharts/highcharts-more";
import solidGauge from "highcharts/modules/solid-gauge";
import { ArrowDownLeftIcon, ArrowUpRightIcon, BoltIcon, UserIcon, UserPlusIcon } from "@heroicons/react/20/solid";


const Charts1 = () => {
    const [valueStartDate, setValueStartDate] = React.useState<Dayjs | null>(dayjs(''));
    const [valueEnddate, setValueEndDate] = React.useState<Dayjs | null>(dayjs(''));

    // useEffect(() => {
    highchartsMore(Highcharts);
    solidGauge(Highcharts);
    //   })



    const options = {
        chart: {
            // type: 'area',
            // type: 'spline',
            type: 'areaspline',
            backgroundColor: 'transparent',
            height: 350
        },
        title: {
            text: 'Revenue 01 Jan 2023 - 31 Dec 2023',
            align: 'left'
        },
        subtitle: {
            text: 'Rp 430,000,000 <span style= "color: #00B050;">1.3%</span> VS LAST YEAR',
            useHTML: true,
            style: {
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#333'
            },
            align: 'left'
        },
        xAxis: {
            categories: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            tickmarkPlacement: 'on',
            gridLineWidth: 0,
            title: {
                enabled: false
            },
            plotLines: [{
                color: '#000000',
                width: 2,
                value: 32000,
                dashStyle: 'Dash',
                zInddex: 10,
                label: {
                    text: '1,348 Subs',
                    style: {
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        fotSize: '12px'
                    },
                },
                events: {
                    click: function () {
                        alert('Click On the Line!');
                    }
                }
            }],
        },
        yAxis: {
            title: {
                text: 'Revenue'
            },
            min: 0,
            max: 50000,
            tickInterval: 10000,
            gridLineDashStyle: 'Dash',
            // gridLineWidth: 1,
            // gridLineColor: '#eee',
            // labels: {
            //     formatter: onRemoveAnchor(dataa)
            // },

        },
        // tooltip: {
        //     // formatter: function () {
        //     //     return '<b>' + this.x + '</b><br/>' + 'Rp ' + this.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'k';
        //     // },
        //     style: {
        //         fontSize: '12px',
        //         fontWeight: 'normal'
        //     },
        //     backgroundColor: '#fff',
        //     borderColor: '#ccc',
        //     borderWidth: 1
        // },
        legend: {
            enabled: false
        },
        plotOptions: {
            // area: {
            //     marker: {
            //         radius: 2
            //     },
            //     lineWidth: 1,
            //     color: {
            //         linearGradient: {
            //             x1: 0,
            //             y1: 0,
            //             x2: 0,
            //             y2: 1
            //         },
            //         stops: [
            //             [0, '#00AAD2'],
            //             [0.7, '#A3DCFF']
            //         ]
            //     },
            //     states: {
            //         hover: {
            //             lineWidth: 1
            //         }
            //     },
            //     threshold: null
            // },
            // spline: {
            //     marker: {
            //         enabled: false
            //     },
            //     lineWidth: 2,
            //     color: {
            //         linearGradient: { x1: 0, x2: 1, y1: 0, y2: 0 },
            //         stops: [
            //             [0, '#00C39A'],
            //             [1, '#E1F9FF']
            //         ]
            //     },
            //     fill: {
            //         linearGradient: { x1: 0, x2: 1, y1: 0, y2: 1 },
            //         stops: [
            //             [0, 'rgba(0, 195, 154, 0,5)'],
            //             [1, 'rgba(225, 249, 225, 0,5)']
            //         ]
            //     }
            // }
            areaspline: {
                marker: {
                    // enabled: false
                    radius: 2
                },
                lineWidth: 2,
                // color: {
                //     linearGradient: {
                //         x1: 0,
                //         x2: 0,
                //         y1: 0,
                //         y2: 1
                //     },
                //     stops: [
                //         [0, '#00AAD2'],
                //         [0.7, '#A3DCFF']
                //     ]
                // },
                // fill: {
                //     linearGradient: { x1: 0, x2: 1, y1: 0, y2: 1 },
                //     stops: [
                //         [0, 'rgba(0, 195, 154, 0,5)'],
                //         [1, 'rgba(225, 249, 225, 0,5)']
                //     ]
                // }
            }
        },
        series: [{
            name: 'Revenue',
            data: [0, 32000, 18000, 28000, 40000, 33000, 32000, 29000, 25000, 20000, 38000],
            color: '#00AAD2',
            // color: {
            //     linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            //     stops: [
            //         [0, '#fa4fed'],
            //         [1, '#5897ff']
            //     ]
            // },
            fillOpacity: 1,
            fillColor: {
                linearGradient: {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1
                },
                stops: [
                    [0, '#00AAD2'],
                    [0, '#A3DCFF'],
                    [1, '#FFFFFF']
                ]
            },
            arearange: {
                enableMouseTracking: false,
                states: {
                    inactive: {
                        enabled: false
                    }
                },
                color: 'red',
                fillOpacity: 1 / 3,
                lineWidth: 0
            }
            // marker: {
            //     enabled: true,
            //     symbol: 'circle',
            //     radius: 4,
            //     fillColor: '#00a1d3',
            //     lineWidth: 0,
            //     states: {
            //         hover: {
            //             lineWidth: 1,
            //             lineColor: '#00a1d3'
            //         }
            //     }
            // }
        }],
        // tooltip: {
        //     shared: true,
        //     valueSuffix: 'k'
        // },
        // credits: {
        //     enabled: false
        // }
    }

    const solidgaugeBlue = {
        chart: {
            type: 'solidgauge',
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            // plotBorderWidth: 0,
            plotShadow: false,
            height: '50%'
        },
        title: {
            text: null
        },
        pane: {
            startAngle: -90,
            endAngle: 90,
            background: null,
            // background: {
            //     backgroundColor: '#E5EAFC',
            //     borderWidth: 0,
            //     innerRadius: '60%',
            //     outerRadius: '100%',
            //     shape: 'arc'
            // },
            center: ['50%', '70%'],
            size: '140%'
        },

        exporting: {
            enabled: false
        },

        tooltip: {
            enabled: false
        },

        // the value axis
        yAxis: {
            min: 0,
            max: 100,
            // tickPixelInterval: 72,
            tickPosition: 'inside',
            tickColor: '#FFFFFF',
            tickLength: 20,
            tickWidth: 2,
            minorTickInterval: null,
            labels: {
                distance: 20,
                style: {
                    fontSize: '14px'
                }
            },
            lineWidth: 0,
            plotBands: [
                {
                    from: 0,
                    to: 100,
                    color: '#E5EAFC',
                    // thickness: 20,
                    // borderRadius: '50%'
                }
            ]
        },

        plotOptions: {
            solidgauge: {
                borderRadius: 3,
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                },
            }
        },
        series: [{
            name: 'Speed',
            color: 'red',
            // data: [72],
            data: [{
                color: '#00AAD2',
                radius: '100%',
                innerRadius: '88%',
                y: 65
            }],
            tooltip: {
                valueSuffix: ' %'
            },
            dataLabels: {
                format:
                    '<div style="text-align:center">' +
                    '<span style="font-size:25px">{y} %</span><br/>' +
                    '<span style="font-size:10px;opacity:0.4">Subscription</span><br/>' +
                    '<span style="font-size:10px;opacity:0.4">Cancelation Rate</span>' +
                    '</div>',
                borderWidth: 0,
                color: (
                    Highcharts.defaultOptions.title &&
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || '#333333',
                style: {
                    fontSize: '16px'
                }
            },
            dial: {
                radius: '80%',
                backgroundColor: 'gray',
                baseWidth: 12,
                baseLength: '0%',
                rearLength: '0%'
            },
            pivot: {
                backgroundColor: 'gray',
                radius: 6
            }

        }]
    }

    const solidgaugeRed = {
        chart: {
            type: 'solidgauge',
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            // plotBorderWidth: 0,
            plotShadow: false,
            height: '50%'
        },
        title: {
            text: null
        },
        pane: {
            startAngle: -90,
            endAngle: 90,
            background: null,
            // background: {
            //     backgroundColor: '#E5EAFC',
            //     borderWidth: 0,
            //     innerRadius: '60%',
            //     outerRadius: '100%',
            //     shape: 'arc'
            // },
            center: ['50%', '70%'],
            size: '140%'
        },

        exporting: {
            enabled: false
        },

        tooltip: {
            enabled: false
        },

        // the value axis
        yAxis: {
            min: 0,
            max: 100,
            // tickPixelInterval: 72,
            tickPosition: 'inside',
            tickColor: '#FFFFFF',
            tickLength: 20,
            tickWidth: 2,
            minorTickInterval: null,
            labels: {
                distance: 20,
                style: {
                    fontSize: '14px'
                }
            },
            lineWidth: 0,
            plotBands: [
                {
                    from: 0,
                    to: 100,
                    color: '#E5EAFC',
                    // thickness: 20,
                    // borderRadius: '50%'
                }
            ]
        },

        plotOptions: {
            solidgauge: {
                borderRadius: 3,
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                },
            }
        },
        series: [{
            name: 'Speed',
            color: 'red',
            // data: [72],
            data: [{
                color: 'red',
                radius: '100%',
                innerRadius: '88%',
                y: 72
            }],
            tooltip: {
                valueSuffix: ' %'
            },
            dataLabels: {
                format:
                    '<div style="text-align:center">' +
                    '<span style="font-size:25px">{y} %</span><br/>' +
                    '<span style="font-size:10px;opacity:0.4">Subscription</span><br/>' +
                    '<span style="font-size:10px;opacity:0.4">Cancelation Rate</span>' +
                    '</div>',
                borderWidth: 0,
                color: (
                    Highcharts.defaultOptions.title &&
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || '#333333',
                style: {
                    fontSize: '16px'
                }
            },
            dial: {
                radius: '80%',
                backgroundColor: 'gray',
                baseWidth: 12,
                baseLength: '0%',
                rearLength: '0%'
            },
            pivot: {
                backgroundColor: 'gray',
                radius: 6
            }

        }]
    }


    const barChart = {
        chart: {
            type: 'bar'
        },
        title: {
            text: null
        },
        subtitle: {
            text: '<span style= "marginBottom:21;">oioi</span>',
            align: 'center'
        },
        xAxis: {
            categories: ['Africa', 'America', 'Asia', 'Europe'],
            title: {
                text: null
            },
            gridLineWidth: 1,
            lineWidth: 0
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Population (millions)',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            },
            gridLineWidth: 0
        },
        tooltip: {
            valueSuffix: ' millions'
        },
        plotOptions: {
            bar: {
                borderRadius: '50%',
                dataLabels: {
                    enabled: true
                },
                groupPadding: 0.1
            }
        },
        legend: {
            layout: 'horizontal',
            align: 'left',
            x: 70,
            verticalAlign: 'top',
            y: 0,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Year 1990',
            data: [632, 727, 3202, 721]
        }, {
            name: 'Year 2000',
            data: [814, 841, 3714, 726]
        }, {
            name: 'Year 2021',
            data: [1393, 1031, 4695, 745]
        }]
    }

    //------------------------------3----------------------------
    // donut Chart
    const totalSubscriptionbyPlan = {
        chart: {
            type: 'pie',
            custom: {}
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: 'Total Subscription by Plan',
            align: 'left'
        },
        // subtitle: {
        //     text: '<span style="font-size: 25px; color:black; font-weight:bold"><strong>780</strong></span>'
        // },
        subtitle: {
            useHTML: true,
            text: getSubtitle(),
            floating: true,
            verticalAlign: 'middle',
            y: 10
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 80,
            floating: true,
            // borderWidth: 1,
            backgroundColor: '#FFFFFF',
            shadow: false
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderRadius: 8,
                // dataLabels: [{
                //     enabled: true,
                //     distance: 20,
                //     format: '{point.name}'
                // }, {
                //     enabled: true,
                //     distance: -15,
                //     format: '{point.percentage:.0f}%',
                //     style: {
                //         fontSize: '0.9em'
                //     }
                // }],
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Registrations',
            colorByPoint: true,
            innerSize: '75%',
            data: [
                {
                    name: 'Basic Charge',
                    y: 1404,
                    revenue: 13000000,
                    revenueChange: 2.8,
                    color: '#2196F3'
                    // sliced: true,
                    // selected: true
                }, {
                    name: 'Eco Drive',
                    y: 1224,
                    revenue: 230560000,
                    revenueChange: -5.56,
                    color: '#1976D2'
                }, {
                    name: 'Ultimate Power',
                    y: 1029,
                    revenue: 104330120,
                    revenueChange: 2.8,
                    color: '#0D47A1'
                }
            ]
        }]
    }
    function getSubtitle() {
        // const totalNumber = getData(input.value)[0][1].toFixed(2);
        return `<span style="font-size: 25px; color:black; font-weight:bold"><strong>780</strong></span>`;
    }

    // pie Chart
    const transactionPaidbyPaymentMethod = {
        chart: {
            type: 'pie',
            option3d: {
                enabled: true,
                alpha: 45,
                beta: 0
            }
        },
        title: {
            text: 'Transaction Paid by Payment Method',
            align: 'left'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>  ({point.y:,.0f})',
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 80,
            floating: true,
            // borderWidth: 1,
            backgroundColor: '#FFFFFF',
            shadow: false
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                // depth: 35,
                // dataLabels: {
                //     enabled: false
                // },
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                },
                showInLegend: true
            }
        },
        series: [{
            // type: 'pie',
            name: 'Subscription',
            colorByPoint: true,
            // innerSize: '75%',
            data: [
                {
                    name: 'BCA VA',
                    y: 1404,
                    revenue: 13000000,
                    revenueChange: 2.8,
                    color: '#00809e'
                    // sliced: true,
                    // selected: true
                }, {
                    name: 'QRIS',
                    y: 1224,
                    revenue: 230560000,
                    revenueChange: -5.56,
                    color: '#00aad2'
                }, {
                    name: 'Gopay',
                    y: 1029,
                    revenue: 104330120,
                    revenueChange: 2.8,
                    color: '#33bbdb'
                }, {
                    name: 'Mandiri VA',
                    y: 1029,
                    revenue: 98565000,
                    revenueChange: 2.8,
                    color: '#66cce4'
                }, {
                    name: 'BRI Direct',
                    y: 1029,
                    revenue: 56440000,
                    revenueChange: 2.8,
                    color: '#99dded'
                }
            ]
        }]
    }
    //----------------------------------------------------------

    //------------------------------4----------------------------
    const totalSubscriptionbyPaymentStatus = {
        chart: {
            type: 'pie',
            custom: {}
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: 'Total Subscription by Payment Status',
            align: 'left'
        },
        // subtitle: {
        //     text: '<span style="font-size: 25px; color:black; font-weight:bold"><strong>780</strong></span>'
        // },
        subtitle: {
            useHTML: true,
            text: getSubtitle1(),
            floating: true,
            verticalAlign: 'middle',
            y: 35,
            x: 15
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderRadius: 8,
                // dataLabels: [{
                //     enabled: true,
                //     distance: 20,
                //     format: '{point.name}'
                // }, {
                //     enabled: true,
                //     distance: -15,
                //     format: '{point.percentage:.0f}%',
                //     style: {
                //         fontSize: '0.9em'
                //     }
                // }],
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Registrations',
            colorByPoint: true,
            innerSize: '75%',
            data: [
                {
                    name: 'Paid',
                    y: 600,
                    color: '#7FE47E'
                    // sliced: true,
                    // selected: true
                }, {
                    name: 'Waiting for Payment',
                    y: 200,
                    color: '#FFAE4C'
                }, {
                    name: 'Expired',
                    y: 100,
                    color: '#C14940'
                }, {
                    name: 'Failed',
                    y: 30,
                    color: '#FF928A'
                }, {
                    name: 'Canceled',
                    y: 30,
                    color: '#E46157'
                }
            ]
        }]
    }
    function getSubtitle1() {
        // const totalNumber = getData(input.value)[0][1].toFixed(2);
        return `<span style="font-size: 25px; color:black; font-weight:bold"><strong>1,000</strong></span>`;
    }

    const totalSubscriptionbyStatus = {
        chart: {
            type: 'pie',
            custom: {}
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: 'Total Subscription by Status',
            align: 'left'
        },
        // subtitle: {
        //     text: '<span style="font-size: 25px; color:black; font-weight:bold"><strong>780</strong></span>'
        // },
        subtitle: {
            useHTML: true,
            text: getSubtitle2(),
            floating: true,
            verticalAlign: 'middle',
            y: 15,
            x: -15
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderRadius: 8,
                // dataLabels: [{
                //     enabled: true,
                //     distance: 20,
                //     format: '{point.name}'
                // }, {
                //     enabled: true,
                //     distance: -15,
                //     format: '{point.percentage:.0f}%',
                //     style: {
                //         fontSize: '0.9em'
                //     }
                // }],
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Registrations',
            colorByPoint: true,
            innerSize: '75%',
            data: [
                {
                    name: 'Active',
                    y: 1404,
                    color: '#FFAE4C'
                    // sliced: true,
                    // selected: true
                }, {
                    name: 'Inactive',
                    y: 1224,
                    color: '#7FE47E'
                }, {
                    name: 'free',
                    y: 1029,
                    color: '#2196F3'
                }
            ]
        }]
    }

    const totalUsers = {
        chart: {
            type: 'pie',
            custom: {}
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: 'Total Users',
            align: 'left'
        },
        // subtitle: {
        //     text: '<span style="font-size: 25px; color:black; font-weight:bold"><strong>780</strong></span>'
        // },
        subtitle: {
            useHTML: true,
            text: getSubtitle2(),
            floating: true,
            verticalAlign: 'middle',
            y: 25
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderRadius: 8,
                // dataLabels: [{
                //     enabled: true,
                //     distance: 20,
                //     format: '{point.name}'
                // }, {
                //     enabled: true,
                //     distance: -15,
                //     format: '{point.percentage:.0f}%',
                //     style: {
                //         fontSize: '0.9em'
                //     }
                // }],
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Registrations',
            colorByPoint: true,
            innerSize: '75%',
            data: [
                {
                    name: 'Active',
                    y: 1404,
                    color: '#7FE47E'
                    // sliced: true,
                    // selected: true
                }, {
                    name: 'Inactive',
                    y: 1224,
                    color: '#FFAE4C'
                }
            ]
        }]
    }
    function getSubtitle2() {
        // const totalNumber = getData(input.value)[0][1].toFixed(2);
        return `<span style="font-size: 25px; color:black; font-weight:bold"><strong>1,604</strong></span>`;
    }


    // const pieChart = {
    //     chart: {
    //         type: 'pie'
    //     },
    //     title: {
    //         text: 'Egg Yolk Composition'
    //     },
    //     tooltip: {
    //         valueSuffix: '%'
    //     },
    //     subtitle: {
    //         text:
    //             'Source:<a href="https://www.mdpi.com/2072-6643/11/3/684/htm" target="_default">MDPI</a>'
    //     },
    //     plotOptions: {
    //         series: {
    //             allowPointSelect: true,
    //             cursor: 'pointer',
    //             dataLabels: [{
    //                 enabled: true,
    //                 distance: 20
    //             }, {
    //                 enabled: true,
    //                 distance: -40,
    //                 format: '{point.percentage:.1f}%',
    //                 style: {
    //                     fontSize: '1.2em',
    //                     textOutline: 'none',
    //                     opacity: 0.7
    //                 },
    //                 filter: {
    //                     operator: '>',
    //                     property: 'percentage',
    //                     value: 10
    //                 }
    //             }]
    //         }
    //     },
    //     series: [
    //         {
    //             name: 'Percentage',
    //             colorByPoint: true,
    //             data: [
    //                 {
    //                     name: 'Water',
    //                     y: 55.02
    //                 },
    //                 {
    //                     name: 'Fat',
    //                     sliced: true,
    //                     selected: true,
    //                     y: 26.71
    //                 },
    //                 {
    //                     name: 'Carbohydrates',
    //                     y: 1.09
    //                 },
    //                 {
    //                     name: 'Protein',
    //                     y: 15.5
    //                 },
    //                 {
    //                     name: 'Ash',
    //                     y: 1.68
    //                 }
    //             ]
    //         }
    //     ]
    // }
    const [onDisabledThisMonth, SetonDisabledThisMonth] = useState(true);
    const [onDisabledThisYear, SetonDisabledThisYear] = useState(false);
    const onOpenThisMonth = () => {
        alert('MONTH');
        SetonDisabledThisMonth(true)
        SetonDisabledThisYear(false)

    }
    const onOpenThisYear = () => {
        alert('open This Year');
        SetonDisabledThisYear(true)
        SetonDisabledThisMonth(false)

    }



    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F8FE', width: '100%' }}>
                {/* <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#F3F8FE', marginLeft: '980px' }}> */}
                <div style={{ display: 'flex', flexDirection: 'row', position: 'absolute', right: '20px', top: '90px' }}>
                    <Button type='button' disabled={onDisabledThisMonth} variant="contained" onClick={onOpenThisMonth} sx={{ height: 50, marginTop: 2 }}>
                        This Month
                    </Button>
                    <Button type='button' disabled={onDisabledThisYear} variant="contained" onClick={onOpenThisYear} sx={{ height: 50, marginTop: 2, marginRight: 2 }}>
                        This Year
                    </Button>

                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                        <DatePicker
                            value={valueStartDate}
                            //   onChange={(event: Dayjs | null) =>
                            //     setFilter({ ...filter, MinValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
                            //   }
                            onChange={(newValue) => setValueStartDate(newValue)}
                            label='Start Date'
                            format='DD-MM-YYYY'
                            sx={{ width: "150px", backgroundColor: 'white', marginTop: 2, boxShadow:1 }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs} dateLibInstance={dayjs}>
                        <DatePicker
                            value={valueEnddate}
                            minDate={dayjs(valueStartDate)}
                            //   onChange={(event: Dayjs | null) =>
                            //     setFilter({ ...filter, MaxValidFrom: (event && event.format('YYYY-MM-DD')) || '' })
                            //   }
                            onChange={(newValue) => setValueEndDate(newValue)}
                            label='End Date'
                            format='DD-MM-YYYY'
                            sx={{ width: "150px", backgroundColor: 'white', marginTop: 2, boxShadow:1  }}
                        />
                    </LocalizationProvider>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F8FE', width: '100%' }}>
                    <div style={{ height: 400, padding: 20, display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '60%', paddingTop: 5, paddingBottom: 5, paddingLeft: 17, paddingRight: 17, backgroundColor: '#FFFFFF' }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '40%', backgroundColor: '#F3F8FE', marginLeft: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', height: 175, backgroundColor: '#F3F8FE' }}>
                                <div style={{ padding: 24, width: '50%', backgroundColor: '#FFFFFF' }}>
                                    {/* <Typography>Gauge</Typography> */}
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={solidgaugeBlue}
                                    />
                                    {/* <Gauge width={100} height={100} value={60} startAngle={-90} endAngle={90} /> */}
                                </div>
                                <div style={{ padding: 24, width: '50%', marginLeft: 20, backgroundColor: '#FFFFFF' }}>
                                    {/* <Typography>Gauge</Typography> */}
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={solidgaugeRed}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', height: 184, backgroundColor: '#F3F8FE', marginTop: 20 }}>
                                <div style={{
                                    width: "33%",
                                    paddingTop: 17,
                                    paddingBottom: 17,
                                    paddingLeft: 14,
                                    paddingRight: 14,
                                    marginRight: 20,
                                    backgroundColor: '#FFFFFF'
                                }}>
                                    <div style={{ width: 48, height: 48, backgroundColor: '#AFECEF', padding: 8 }}>
                                        <UserIcon style={{ height: 30, width: 30, color: '#036672' }} />
                                    </div>
                                    <div style={{ marginTop: 14, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Typography style={{ fontSize: 40, fontWeight: 500 }}><strong>780</strong></Typography>
                                        <div style={{ marginTop: 16 }}>
                                            <span className="dot" style={{ height: '20px', width: '20px', backgroundColor: '#31C48D', borderRadius: '50%', display: 'inline-block' }}>
                                                <ArrowUpRightIcon style={{ height: 20, width: 20, color: '#FFFFFF' }} />
                                            </span>
                                        </div>
                                        <Typography style={{ color: '#31C48D', marginTop: 16 }}><strong>+6.8%</strong></Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography style={{ color: '#999999' }}>Total Users</Typography>
                                    </div>
                                </div>
                                <div style={{
                                    width: "33%",
                                    paddingTop: 17,
                                    paddingBottom: 17,
                                    paddingLeft: 14,
                                    paddingRight: 14,
                                    marginRight: 20,
                                    backgroundColor: '#FFFFFF'
                                }}>
                                    <div style={{ width: 48, height: 48, backgroundColor: '#BCF0DA', padding: 8 }}>
                                        <UserPlusIcon style={{ height: 30, width: 30, color: '#046C4E' }} />
                                    </div>
                                    <div style={{ marginTop: 14, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Typography style={{ fontSize: 40, fontWeight: 500 }}><strong>680</strong></Typography>
                                        <div style={{ marginTop: 16 }}>
                                            <span className="dot" style={{ height: '20px', width: '20px', backgroundColor: '#31C48D', borderRadius: '50%', display: 'inline-block' }}>
                                                <ArrowUpRightIcon style={{ height: 20, width: 20, color: '#FFFFFF' }} />
                                            </span>
                                        </div>
                                        <Typography style={{ color: '#31C48D', marginTop: 16 }}><strong>+2.8%</strong></Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography style={{ color: '#999999' }}>User Free to Paid</Typography>
                                    </div>
                                </div>
                                <div style={{
                                    width: "33%",
                                    paddingTop: 17,
                                    paddingBottom: 17,
                                    paddingLeft: 14,
                                    paddingRight: 14,
                                    backgroundColor: '#FFFFFF'
                                }}>
                                    <div style={{ width: 48, height: 48, backgroundColor: '#E1EFFE', padding: 8 }}>
                                        <BoltIcon style={{ height: 30, width: 30, color: '#1A56DB' }} />
                                    </div>
                                    <div style={{ marginTop: 14, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <Typography style={{ fontSize: 40, fontWeight: 500 }}><strong>123</strong></Typography>
                                        <div style={{ marginTop: 16 }}>
                                            <span className="dot" style={{ height: '20px', width: '20px', backgroundColor: '#F05252', borderRadius: '50%', display: 'inline-block' }}>
                                                <ArrowDownLeftIcon style={{ height: 20, width: 20, color: '#FFFFFF' }} />
                                            </span>
                                        </div>
                                        <Typography style={{ color: '#F05252', marginTop: 16 }}><strong>-5.56%</strong></Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography style={{ color: '#999999' }}>User Paid to Paid</Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 410, padding: 20, display: 'flex', flexDirection: 'row', marginTop: -15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '33%', padding: 20, backgroundColor: '#FFFFFF' }}>
                            <Typography>Total Transaction Paid</Typography>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={barChart}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '33%', padding: 20, backgroundColor: '#FFFFFF', marginLeft: 20 }}>
                            <Typography>Total Users</Typography>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={barChart}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '33%', padding: 20, backgroundColor: '#FFFFFF', marginLeft: 20 }}>
                            <Typography>Total Revenue</Typography>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={barChart}
                            />
                        </div>
                    </div>
                    <div style={{ height: 400, padding: 24, display: 'flex', flexDirection: 'row', marginTop: -15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: 20, backgroundColor: '#FFFFFF' }}>
                            {/* <Typography>Total Subscription by Plan</Typography> */}
                            {/* <div style={{ width: 200, height: 200, backgroundColor: 'yellow'}}> */}
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={totalSubscriptionbyPlan}
                            />
                            {/* </div> */}

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%', padding: 20, backgroundColor: '#FFFFFF', marginLeft: 20 }}>
                            {/* <Typography>Transaction Paid by Payment Method</Typography> */}
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={transactionPaidbyPaymentMethod}
                            />
                        </div>
                    </div>
                    <div style={{ height: 400, padding: 24, display: 'flex', flexDirection: 'row', marginTop: -15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '33%', padding: 20, backgroundColor: '#FFFFFF' }}>
                            {/* <Typography>Total Subscription by Payment Status</Typography> */}
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={totalSubscriptionbyPaymentStatus}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '33%', padding: 20, backgroundColor: '#FFFFFF', marginLeft: 20 }}>
                            {/* <Typography>Total Subscription by Status</Typography> */}
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={totalSubscriptionbyStatus}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '33%', padding: 20, backgroundColor: '#FFFFFF', marginLeft: 20 }}>
                            {/* <Typography>Total Users</Typography> */}
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={totalUsers}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>

    )
}

export default Charts1